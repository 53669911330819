import React from "react";
import { Button, Text } from "@chakra-ui/react";

export const MenuButton = ({ ...props }) => {
  return (
    <Button
      variant={"ghost"}
      bg={"brand.primary"}
      color={"brand.white"}
      ml={2}
      _hover={{ bg: "brand.primary" }}
      _focus={{
        boxShadow: "none",
        color: "brand.white",
      }}
      _active={{
        boxShadow: "rgba(0, 0, 0, 0)",
        color: "brand.acents",
      }}
      {...props}
    >
      <Text fontSize={"1rem"} fontWeight={"normal"}>
        {props.children}
      </Text>
    </Button>
  );
};
