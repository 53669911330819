import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import moment from "moment";

import {
  Box,
  Flex,
  Button,
  Menu, MenuButton, MenuList, MenuItem,
  SimpleGrid,
  useDisclosure,
  Text,
  Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton,
} from "@chakra-ui/react";

import { H6 } from "../../design-system/headings/H6";
import { H5 } from "../../design-system/headings/H5";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

import MyQuotesImportModal from "../../components/MyQuotesImportModal/MyQuotesImportModal";
import MyQuotesImportErrorModal from "../../components/MyQuotesImportErrorModal/MyQuotesImportErrorModal";
import MyQuotesItem from "../../components/MyQuotesItem/MyQuotesItem";
import MyQuotesMenuCategories from "../../components/MyQuotesMenuCategories/MyQuotesMenuCategories";
import MyQuotesItemLoader from "../../components/MyQuotesItem/MyQuotesItemLoader";
import { MyQuotesBreadcrumb } from "../../components/MyQuotesBreadcrumb/MyQuotesBreadcrumb";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { Paginator } from "../../components/Paginator/Paginator";

import successCircle from "../../design-system/icons/Arrows/success-circle-fill.svg";

import { actions as quoteActions } from "./ducks/myQuotes.duck";

import useQuery from "../../hooks/useQuery";

const MyQuotesPage = ({
  getRegions,
  fadeIn,
  listQuotes,
  listCurrencies,
  getCategories,
  getExportQuote,
  pauseState,
  enableState,
  ...props
}) => {
  // main states
  const quotesState = useSelector((state) => state?.quotes);

  // Hooks
  const query = useQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenModalMasive,
    onOpen: onOpenModalMasive,
    onClose: onCloseModalMasive,
  } = useDisclosure();

  
  const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure();

  const ref = useRef();
  const history = useHistory();
  const { pathname } = useLocation();

  // useState variables
  const [force, setForce] = useState(0);
  const [exportLoading, setExportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quotesStates, setQuoteStates] = useState("");

  // queries states
  const state = query.get("state");
  const currency_id = query.get("currency_id");
  const region_id = query.get("region_id");
  const page = query.get("page");
  const perPage = query.get("per_page");
  const category_id = query.get("category_id");
  const subcategory_id = query.get("subcategory_id");
  const sub_category_item = query.get("sub_category_item");
  const brand_id = query.get("brand_id");
  const d = query.get("d");
  const from = query.get("from");
  const to = query.get("to");

  //useEffect
  useEffect(() => {
    listQuotes({
      state,
      page,
      perPage,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
      from,
      to,
    });
    listCurrencies();
  }, [
    state,
    page,
    perPage,
    region_id,
    currency_id,
    category_id,
    subcategory_id,
    sub_category_item,
    brand_id,
    from,
    to,
    listQuotes,
    listCurrencies,
  ]);

  useEffect(() => {
    if (quotesState?.result === "exportResult") {
      setExportLoading(false);
      ref && ref.current && ref.current.click();
    }
    if (quotesState?.result) {
      setLoading(false);
      setQuoteStates("");
    }
  }, [quotesState?.result]);

  useEffect(() => {
    if (quotesState?.pause || quotesState?.enable) setQuoteStates("");
  }, [quotesState?.pause, quotesState?.enable]);

  // handle actions
  const handleConfirmExport = () => {
    setExportLoading(true);
    getExportQuote({
      state,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
      from,
      to,
    });
  };

  function handleConfirmImport() {
    listQuotes({
      state,
      page,
      perPage,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
      from,
      to,
    });
  }

  const handleForceState = () => {
    setForce(force + 1);
  };

  const dataItems = quotesState?.quotes;

  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });

  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment()
        .add(-24, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment()
        .add(-1, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment()
        .add(-3, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment()
        .add(-6, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment()
        .add(-12, "M")
        .format("YYYY-MM-DD");
    }
    item?.date &&
      history.push(
        `${pathname}?state=${state}&page=1&per_page=20&from=${newDate}&to=${item.to}&d=${item.d}`
      );
  };

  const handleActiveState = () => {
    onOpenModalMasive();
    setQuoteStates("pause");
  };

  const handleEnableState = () => {
    onOpenModalMasive();
    setQuoteStates("enable");
  };

  const handleSuccess = () => {
    listQuotes({
      state,
      page,
      perPage,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
    });
  };

  const handleRefreshItems = () => {
    listQuotes({ state, page, perPage });
    onClose();
    onCloseModalMasive();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"2.5rem"}
        bg={"brand.background"}
        w="75rem"
        /*{...props}*/
      >
        <Box mb="1.5rem" overflow="unset" zIndex={2}>
          <MyQuotesBreadcrumb />
          <H5 mt="1.5rem">Mis cotizaciones</H5>
          <SimpleGrid mt={"2rem"} minChildWidth="12.5rem" spacing={4}>
            <MyQuotesMenuCategories>Categorías</MyQuotesMenuCategories>
            {/* <MenuBekeu
            height={"3.5rem"}
            mr={"1rem"}
            items={quotesState?.categories}
            category_id
            scroll
          >
            {quotesState?.categories?.find(
              (a) => a.id === Number(category_id)
            )?.name || "Categoría"}
          </MenuBekeu> */}
            {/* <InputBekeu
            isDisabled
            placeholder={"Buscar"}
            bg={"white"}
            lefticon={<BekeuIcon src={search} mr={4} />}
            w={"100%"}
          /> */}
            <MenuBekeu
              scroll
              height={"3.5rem"}
              mr={"1rem"}
              items={[
                { name: "Publicación activa", state: "1" },
                { name: "Publicación pausada", state: "2" },
                { name: "Publicación cancelada", state: "3" },
              ]}
            >
              {state && state === "1"
                ? "Publicación activa"
                : state === "2"
                ? "Publicación pausada"
                : state === "3" && "Publicación cancelada"}
            </MenuBekeu>
            {/* <MenuBekeu
            height={"3.5rem"}
            mr={"1rem"}
            items={quotesState?.regions}
            region_id
          >
            {quotesState?.regions?.find((a) => a.id === Number(region_id))
              ?.name || "Zonas"}
          </MenuBekeu> */}
            <Flex>
              <MenuBekeu
                height={"3.5rem"}
                items={[
                  { name: "Todas las monedas", currency_id_reset: true },
                  ...quotesState?.currencies,
                ]}
                currency_id
                scroll
              >
                {quotesState?.currencies?.find(
                  (a) => a.id === Number(currency_id)
                )?.name || "Moneda"}
              </MenuBekeu>
              {/* <MenuBekeu
              
              height={"3.5rem"}
              mr={"1rem"}
              onClick={onToggleFilters}
            >
              Mas filtros
            </MenuBekeu> */}
            </Flex>

            <MenuBekeu
              height={"3.5rem"}
              leftIcon
              scroll
              setMoment={setMoment}
              state={state}
              items={[
                {
                  name: "Todas",
                  date: "Todas",
                  to: formattedDate,
                  d: 0,
                },
                {
                  name: "Este mes",
                  date: "Este mes",
                  to: formattedDate,
                  d: 1,
                },
                {
                  name: "Últimos 3 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 3,
                },
                {
                  name: "Últimos 6 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 6,
                },
                {
                  name: "Últimos 12 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 12,
                },
              ]}
            >
              {d
                ? d === "0"
                  ? "Todas"
                  : d === "1"
                  ? "Este mes"
                  : d === "3"
                  ? "Últimos 3 meses"
                  : d === "6"
                  ? "Últimos 6 meses"
                  : d === "12" && "Últimos 12 meses"
                : "Todas"}
            </MenuBekeu>
          </SimpleGrid>
        </Box>
        {quotesState?.loading ? (
          <BoxBekeu p="0" w="100%" className={fadeIn}>
            <Flex
              px="1.5rem"
              alignItems="center"
              justifyContent="space-between"
              height={"6rem"}
            >
              <H6>Información de la publicación</H6>
              <Box>
                {dataItems?.length > 0 && (
                  <ButtonBekeu w="fit-content" mr="1.5rem">
                    Exportar
                  </ButtonBekeu>
                )}

                {quotesState?.exportedData && (
                  <a
                    href={quotesState?.exportedData.temporary_signed_url}
                    rel="noopener noreferrer"
                    target="_blank"
                    ref={ref}
                  >
                    {" "}
                  </a>
                )}
                <ButtonBekeu w="fit-content" mr="0.875rem">
                  Importar
                </ButtonBekeu>
                {dataItems?.length > 0 && (
                  <Menu>
                    <MenuButton
                      as={Button}
                      bg="#FFFFFF"
                      border="0.125rem solid"
                      borderRadius="0.3125rem"
                      borderColor={"brand.contentSecondary"}
                      color="brand.contentSecondary"
                      m="0 0.75rem"
                      height="1.25rem"
                      minWidth="1.25rem"
                      padding="0 0 0.5625rem 0"
                      transform="rotate(90deg)"
                      _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                    >
                      ...
                    </MenuButton>
                  </Menu>
                )}
              </Box>
            </Flex>

            {[1, 2].map((dataItem, index) => (
              <MyQuotesItemLoader
                key={index}
                image={dataItem.imagen}
                productName={dataItem.prod_name}
                sku={dataItem.comb_sku}
                size={dataItem.size}
                stateItem={dataItem.state}
                state={dataItem.state}
                isActive={dataItem.state}
                priceState={dataItem.price_state}
                currency={dataItem.moneda}
                currencyId={dataItem.currency_id}
                price={dataItem.comb_price}
                validityDate={dataItem.fecha_vigencia}
                priceId={dataItem.price_id}
                dateFrom={dataItem.date_from}
                providerId={dataItem.provider_id}
                handleForceState={handleForceState}
              />
            ))}
          </BoxBekeu>
        ) : (
          dataItems?.length > 0 && (
            <BoxBekeu p="0" w="100%" className={fadeIn}>
              <Flex
                px="1.5rem"
                alignItems="center"
                justifyContent="space-between"
                height={"6rem"}
              >
                <H6>Información de la publicación</H6>
                <Box>
                  {dataItems?.length > 0 && (
                    <ButtonBekeu
                      w="fit-content"
                      mr="1.5rem"
                      onClick={handleConfirmExport}
                      isLoading={exportLoading}
                    >
                      Exportar
                    </ButtonBekeu>
                  )}

                  {quotesState?.exportedData && (
                    <a
                      href={quotesState?.exportedData.temporary_signed_url}
                      rel="noopener noreferrer"
                      target="_blank"
                      ref={ref}
                    >
                      {" "}
                    </a>
                  )}

                  <ButtonBekeu
                    w="fit-content"
                    onClick={() => onOpen()}
                    mr="0.875rem"
                  >
                    Importar
                  </ButtonBekeu>
                  <MyQuotesImportModal
                    onClose={onClose}
                    isOpen={isOpen}
                    handleConfirmImport={handleConfirmImport}
                  />
                  {dataItems?.length > 0 && (
                    <Menu>
                      <MenuButton
                        as={Button}
                        bg="#FFFFFF"
                        border="0.125rem solid"
                        borderRadius="0.3125rem"
                        borderColor={"brand.contentSecondary"}
                        color="brand.contentSecondary"
                        m="0 0.75rem"
                        height="1.25rem"
                        minWidth="1.25rem"
                        padding="0 0 0.5625rem 0"
                        transform="rotate(90deg)"
                        _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                      >
                        ...
                      </MenuButton>
                      <MenuList bg={"white"} borderRadius={"0.9375rem"} py={"0.75rem"}>
                        <MenuItem
                          _focus={{ bg: "brand.transparent" }}
                          p={"0.75rem 1.5rem"}
                          _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                          isDisabled={state === "3" || state === "2"}
                          onClick={() => handleActiveState()}
                        >
                          Pausar masivamente
                        </MenuItem>
                        <MenuItem
                          _focus={{ bg: "brand.transparent" }}
                          p={"0.75rem 1.5rem"}
                          _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                          isDisabled={state === "3" || state === "1"}
                          onClick={() => handleEnableState()}
                        >
                          Activar masivamente
                        </MenuItem>
                        <MenuItem
                          _focus={{ bg: "brand.transparent" }}
                          p={"0.75rem 1.5rem"}
                          _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                          onClick={onErrorOpen}
                        >
                          Mostrar errores
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Box>
              </Flex>

              <MyQuotesImportErrorModal isOpen={isErrorOpen} onClose={onErrorClose} errors={ quotesState?.errors} />

              <Modal
                isOpen={isOpenModalMasive}
                onClose={onCloseModalMasive}
                isCentered
                size={"xl"}
                onCloseComplete={() => {
                  quotesState?.result && handleRefreshItems();
                }}
              >
                <ModalOverlay />
                <ModalContent borderRadius={"0.75rem"}>
                  <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
                  {quotesState?.result ? (
                    <ModalBody p={"3rem"}>
                      <Flex
                        flexDir={"column"}
                        justifyContent="center"
                        alignItems={"center"}
                        gap={"0.75rem"}
                      >
                        <BekeuIcon w={"3.375rem"} src={successCircle} />
                        <H5 textAlign="center">
                          Los cambios fueron realizados con exito!
                        </H5>
                        <ButtonBekeu
                          my={0}
                          w={"fit-content"}
                          onClick={handleRefreshItems}
                        >
                          Aceptar
                        </ButtonBekeu>
                      </Flex>
                    </ModalBody>
                  ) : (
                    <ModalBody p={"3rem"}>
                      <Flex flexDir={"column"} justifyContent="center">
                        <H5 textAlign="center">
                          {quotesStates === "pause"
                            ? "¿Estás seguro que querés pausar todas tus publicaciones?"
                            : quotesStates === "enable" &&
                              "¿Estás seguro que querés activar todas tus publicaciones?"}
                        </H5>
                        <Paragraphs
                          textAlign="center"
                          lineHeight={"1.375rem"}
                          m={"1.5625rem"}
                        >
                          {quotesStates === "pause"
                            ? "Al pausar tus publicaciones tus productos no estarán disponibles para comprar."
                            : quotesStates === "enable" &&
                              "Al activar tus publicaciones tus productos estarán disponibles para comprar."}
                        </Paragraphs>
                        <Flex w="100%" mt="0.75rem">
                          <ButtonBekeuOutline
                            mr="0.75rem"
                            onClick={onCloseModalMasive}
                          >
                            No, cancelar
                          </ButtonBekeuOutline>
                          <ButtonBekeu
                            my="0"
                            ml="0.75rem"
                            onClick={() => {
                              setLoading(true);
                              quotesStates === "pause"
                                ? pauseState()
                                : quotesStates === "enable" && enableState();
                            }}
                            isLoading={loading}
                          >
                            Si, confirmar
                          </ButtonBekeu>
                        </Flex>
                      </Flex>
                    </ModalBody>
                  )}
                </ModalContent>
              </Modal>
              {dataItems?.length > 0 &&
                dataItems.map((dataItem, index) => (
                  <MyQuotesItem
                    key={index}
                    image={dataItem.imagen}
                    productName={dataItem.prod_name}
                    sku={dataItem.comb_sku}
                    size={dataItem.size}
                    stateItem={dataItem.state}
                    state={dataItem.state}
                    isActive={dataItem.state}
                    priceState={dataItem.price_state}
                    currency={dataItem.moneda}
                    currencyId={dataItem.currency_id}
                    price={dataItem.comb_price}
                    validityDate={dataItem.fecha_vigencia}
                    priceId={dataItem.price_id}
                    dateFrom={dataItem.date_from}
                    providerId={dataItem.provider_id}
                    handleForceState={handleForceState}
                  />
                ))}
            </BoxBekeu>
          )
        )}
        {!quotesState?.loading && dataItems?.length === 0 && (
          <Text mt="1.5rem">No se encontraron resultados</Text>
        )}
        {!quotesState?.loading &&
          quotesState &&
          quotesState?.quotesPagination?.totalPages > 1 && (
            <Paginator
              totalItems={quotesState?.quotesPagination.totalPages}
              perPage={5}
            />
          )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, quoteActions)(MyQuotesPage));
