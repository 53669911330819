import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import bekeuLogo from "../login/bekeuLogo.png";
import arrowLeft from "./../login/assets/arrow-left.png";
import { LoginInput } from "./../login/Components/LoginInput";
import {
  Box,
  Button,
  Flex,
  Img,
  InputGroup,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { Link, Redirect, useLocation } from "react-router-dom";

import { useEffect } from "react";
import * as auth from "../login/ducks/auth.duck";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import successCircleFill from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { NotFoundPageToken } from "../notFoundPageToken/notFoundPageToken";
import useQuery from "../../hooks/useQuery";

const ChangePasswordPage = (props) => {
  const mainState = useSelector((state) => state);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [paramEmail, setParamEmail] = useState("");
  const [paramToken, setParamToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorCodeToken, setErrorCodeToken] = useState(false);
  const query = useQuery();

  const email = query.get("email");
  const formattedEmail = email.trim().replace(" ", "+");

  useEffect(() => {
    setIsLoading(false);

    const params = new URLSearchParams(window.location.search);
    setParamEmail(params.has("email") ? formattedEmail : "");
    setParamToken(params.has("token") ? params.get("token") : "");

    props.checkToken({
      email: params.has("email") ? formattedEmail : "",
      token: params.has("token") ? params.get("token") : "",
    });
  }, []);

  useEffect(() => {
    if (mainState.auth.tokenValid) {
      if (mainState.auth.tokenValid === false) {
        props.logout();
      }
    }
  }, [mainState.auth.tokenValid]);

  useEffect(() => {
    if (mainState.auth.resultPassword) {
      setSuccess(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [mainState.auth.resultPassword]);

  useEffect(() => {
    if (mainState.auth.resultPassword === false) {
      setErrorCodeToken(true);
      setErrorPassword(false);
    }
  }, [mainState.auth.resultPassword]);

  const simulatorError = true;

  const handleValidationPassword = (e) => {
    setPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };
  const handleValidationConfirmPassword = (e) => {
    setConfirmPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };

  const handleCreatePassword = () => {
    let newParamEmail = paramEmail.replace(" ", "+");
    setLoading(true);
    if (password === confirmPassword) {
      props.passwordUpdate({
        email: newParamEmail,
        password,
        confirmPassword,
        token: paramToken,
      });
    } else {
      setErrorPassword(true);
      setErrorCodeToken(false);
    }
  };

  //email and password validation states
  const passwordValidation =
    password.length < 8 && password.length !== 0 ? true : false;
  const confirmPasswordValidation = password !== confirmPassword;
  const buttonValidation = passwordValidation || confirmPasswordValidation;

  return (
    <Flex
      bg={"brand.background"}
      h={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      className="fade"
      p={["1rem","unset","unset","unset"]}
      flexDir={"column"}
    >
        <Flex justifyContent={"center"}>
          <Img src={bekeuLogo} alt="bekeu logo" h={"fit-content"} />
        </Flex>

        <Flex flexDir={"column"} alignItems={"center"} textAlign={"center"} fontSize={"1.5rem"} m={"1.5rem"} w={"100%"}>
          <Text fontWeight={"semibold"} lineHeight="2rem">
            Restablecer contraseña
          </Text>
          <Text fontWeight={"normal"} fontSize={"1rem"} mt={4}>
            Ingresá tu nueva contraseña
          </Text>
        </Flex>

      {mainState.auth.tokenValid === null ? (
        <Spinner color="gray.300" size="lg" />
      ) : (
        <>
          {mainState.auth.tokenValid === false ? (
            <NotFoundPageToken />
          ) : (
            <Box
            bg={"brand.white"}
            maxW={"29.43rem"}
            w={"100%"}
            borderWidth="0.0625rem"
            borderRadius="lg"
            overflow="hidden"
            shadow={"md"}
            mt={"2rem"}
            >
              {success ? (
                <Flex
                  flexDir={"column"}
                  m={"2rem"}
                  alignItems="center"
                  justifyContent={"center"}
                
                >
                  <BekeuIcon src={successCircleFill} />
                  <H5 width={"100%"} textAlign="center" mt={"1rem"}>
                    Contraseña restablecida con éxito
                  </H5>
                  <Link to={"/login"}>
                    <ButtonBekeu mt={"2rem"} w={"100%"}>Ingresar</ButtonBekeu>
                  </Link>
                </Flex>
              ) : (
                <Flex
                  flexDir={"column"}
                  alignItems={"center"}
                  justifyContent={"space-evenly"}
                  minH={"19.5rem"}
                >
                  <Flex     
                    flexDir={"column"}
                    w={"75%"}
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                    mt={"2rem"}>
                    <LoginInput
                      placeholder="Nueva contraseña"
                      value={password}
                      isInvalid={passwordValidation}
                      onChange={handleValidationPassword}
                      isPassword
                    />
                    <LoginInput
                      placeholder="Repetir nueva contraseña"
                      value={confirmPassword}
                      isInvalid={confirmPasswordValidation}
                      onChange={handleValidationConfirmPassword}
                      isPassword
                    />
                  </Flex>
                  {errorPassword && (
                    <Text
                      color={"brand.error"}
                      fontSize={"12px"}
                      fontWeight={300}
                    >
                      Las contraseñas no coinciden. Por favor intenta
                      nuevamente.
                    </Text>
                  )}
                  {errorCodeToken && (
                    <Text
                      color={"brand.error"}
                      fontSize={"12px"}
                      fontWeight={300}
                    >
                      Esta solicitud de cambio de contraseña ha expirado, tienes
                      que solicitar una nueva.
                    </Text>
                  )}
                  <Flex flexDir={"column"} w={"100%"} alignItems="center">
                    <Button
                      colorScheme={"blackAlpha"}
                      bg={"brand.primary"}
                      color={"brand.white"}
                      mb={8}
                      height={50}
                      borderRadius={12}
                      isDisabled={buttonValidation}
                      w={"75%"}
                      onClick={handleCreatePassword}
                      _focus={{
                        boxShadow:
                          "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                      }}
                      isLoading={loading}
                    >
                      Restablecer
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};

export default injectIntl(connect(null, auth.actions)(ChangePasswordPage));
