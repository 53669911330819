import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import arrowRight from "../../design-system/icons/Arrows/arrow-right.svg";

import { useHistory, useLocation } from "react-router-dom";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

export const ApproveOrdersBreadcrumb = () => {
  const location = useLocation().pathname;
  const history = useHistory();

  return (
    <Breadcrumb spacing="8px" separator={<BekeuIcon src={arrowRight} />}>
      <BreadcrumbItem>
        <BreadcrumbLink
          onClick={() => history.push("/")}
          _hover={{ color: "brand.hover" }}
          fontFamily={"Open Sans"}
          fontWeight={400}
          fontSize={"14px"}
        >
          Inicio
        </BreadcrumbLink>
      </BreadcrumbItem>
      {location.includes("my-orders") && (
        <BreadcrumbItem>
          <BreadcrumbLink
            color={location === "/my-orders" && "brand.primary"}
            onClick={() =>
              history.push("/my-orders")
            }
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Mis compras
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location.includes("sales") && (
        <BreadcrumbItem>
          <BreadcrumbLink
            color={location === "/sales" && "brand.primary"}
            onClick={() => history.push("/sales/?state=2&page=1&per_page=20")}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Mis ventas
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location.includes("sales/order-detail") && (
        <BreadcrumbItem>
          <BreadcrumbLink
            color={location.includes("sales/order-detail") && "brand.primary"}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Detalle de orden de venta
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location.includes("shipping") && (
        <BreadcrumbItem>
          <BreadcrumbLink
            color={location === "/shipping" && "brand.primary"}
            onClick={() =>
              history.push("/shipping?state=1&page=1&per_page=20")
            }
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Mis envíos
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location.includes("/my-orders/order-detail") && (
        <BreadcrumbItem color={"brand.primary"}>
          <BreadcrumbLink
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            <Text>Estado de la compra </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location.includes("approve-orders") && (
        <BreadcrumbItem>
          <BreadcrumbLink
            color={location === "/approve-orders" && "brand.primary"}
            onClick={() => history.goBack()}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Aprobar pedidos
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location === "/approve-orders/delegate-orders" && (
        <BreadcrumbItem color={"brand.primary"}>
          <BreadcrumbLink
            onClick={() => history.push("/approve-orders/delegate-orders")}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            <Text>Delegar pedidos</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location === "/approve-orders/edit-delegate" && (
        <BreadcrumbItem color={"brand.primary"}>
          <BreadcrumbLink
            onClick={() => history.push("/approve-orders/edit-delegate")}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            <Text>Editar delegación</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location.includes("/approve-orders/order-detail") && (
        <BreadcrumbItem color={"brand.primary"}>
          <BreadcrumbLink
            onClick={() => history.push("/approve-orders/order-detail")}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            <Text>Detalle del pedido</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};
