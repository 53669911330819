import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

import {
  listQuotes,
  confirmMyQuotesQuote,
  claimMyQuotesQuote,
  listDetailQuote,
  getRegions,
  getImportQuote,
  getExportQuote,
  editMyQuotesPrice,
  changeState,
  listCurrencies,
  getCategories,
  pauseState,
  enableState,
  getShippingPrices,
} from "../cruds/myQuotes.crud";

export const actionTypes = {
  LIST_QUOTES: "LIST_QUOTES",
  FILL_QUOTE_LIST: "FILL_QUOTE_LIST",
  FILL_QUOTE_PAGINATION_DATA: "FILL_QUOTE_PAGINATION_DATA",
  CONFIRM_MY_QUOTES_QUOTE: "CONFIRM_MY_QUOTES_QUOTE",
  CLAIM_MY_QUOTES_QUOTE: "CLAIM_MY_QUOTES_QUOTE",
  LIST_DETAIL_QUOTE: "LIST_DETAIL_QUOTE",
  FILL_QUOTE_DETAIL: "FILL_QUOTE_DETAIL",
  SET_RESULT: "SET_RESULT",
  GET_REGIONS: "GET_REGIONS",
  GET_QUOTES: "GET_QUOTES",
  FILL_REGIONS: "FILL_REGIONS",
  GET_IMPORT_QUOTE: "GET_IMPORT_QUOTE",
  GET_EXPORT_QUOTE: "GET_EXPORT_QUOTE",
  FILL_IMPORT_QUOTE: "FILL_IMPORT_QUOTE",
  FILL_EXPORT_QUOTE: "FILL_EXPORT_QUOTE",
  EDIT_MY_QUOTES_PRICE: "EDIT_MY_QUOTES_PRICE",
  FILL_MY_QUOTES_PRICE: "FILL_MY_QUOTES_PRICE",
  GET_CHANGE_STATE: "GET_CHANGE_STATE",
  FILL_CHANGE_STATE: "FILL_CHANGE_STATE",
  LIST_CURRENCIES: "LIST_CURRENCIES",
  FILL_LIST_CURRENCIES: "FILL_LIST_CURRENCIES",
  LIST_CATEGORIES_QUOTES: "LIST_CATEGORIES_QUOTES",
  FILL_LIST_CATEGORIES_QUOTES: "FILL_LIST_CATEGORIES_QUOTES",
  PAUSE_STATE: "PAUSE_STATE",
  FILL_PAUSE_STATE: "FILL_PAUSE_STATE",
  ENABLE_STATE: "ENABLE_STATE",
  FILL_ENABLE_STATE: "FILL_ENABLE_STATE",
  GET_SHIPPING_PRICES: "GET_SHIPPING_PRICES",
  FILL_SHIPPING_PRICES: "FILL_SHIPPING_PRICES",
  SET_ERRORS: "SET_ERRORS",
};

const initialState = {
  quotes: [],
  loading: false,
  quotesPagination: {
    currentPage: 1,
    perPage: 20,
    totalPages: 1,
    totalRows: 0,
  },
  quoteDetail: {},
  result: null,
  regions: [],
  imports: [],
  lists: false,
  states: [],
  currencies: [],
  categories: [],
  exportedData: null,
  pause: [],
  enable: [],
  shipping: [],
  errors:[]
};

export const reducer = persistReducer(
  { storage, key: "demo1-quotes", whitelist: ["quotes"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_QUOTES: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.LIST_DETAIL_QUOTE: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_QUOTE_LIST: {
        return {
          ...state,
          quotes: action.payload,
          loading: false,
        };
      }
      case actionTypes.FILL_QUOTE_DETAIL: {
        return {
          ...state,
          quotes: action.payload,
          loading: false,
        };
      }

      case actionTypes.FILL_QUOTE_PAGINATION_DATA: {
        return {
          ...state,
          quotesPagination: action.payload,
          loading: false,
        };
      }

      case actionTypes.CONFIRM_MY_QUOTES_QUOTE: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.CLAIM_MY_QUOTES_QUOTE: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.SET_RESULT: {
        return {
          ...state,
          result: action.payload,
          loading: false,
        };
      }

      case actionTypes.GET_REGIONS: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_REGIONS: {
        return {
          ...state,
          regions: action.payload,
          loading: false,
        };
      }

      case actionTypes.GET_IMPORT_QUOTE: {
        return {
          ...state,
        };
      }

      case actionTypes.FILL_IMPORT_QUOTE: {
        return {
          ...state,
          imports: action.payload,
          loading: false,
        };
      }

      case actionTypes.GET_EXPORT_QUOTE: {
        return {
          ...state,
        };
      }

      case actionTypes.FILL_EXPORT_QUOTE: {
        return {
          ...state,
          exportedData: action.payload,
          result: null,
          loading: false,
        };
      }

      case actionTypes.EDIT_MY_QUOTES_PRICE: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.FILL_MY_QUOTES_PRICE: {
        return {
          ...state,
          lists: action.payload,
          loading: false,
        };
      }

      case actionTypes.GET_CHANGE_STATE: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.LIST_CURRENCIES: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_CHANGE_STATE: {
        return {
          ...state,
          loading: false,
          states: action.payload,
        };
      }
      case actionTypes.FILL_LIST_CURRENCIES: {
        return {
          ...state,
          currencies: action.payload,
          loading: false,
        };
      }

      case actionTypes.LIST_CATEGORIES_QUOTES: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.FILL_LIST_CATEGORIES_QUOTES: {
        return {
          ...state,
          categories: action.payload,
          loading: false,
        };
      }

      case actionTypes.PAUSE_STATE: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.FILL_PAUSE_STATE: {
        return {
          ...state,
          pause: action.payload,
          loading: false,
        };
      }

      case actionTypes.ENABLE_STATE: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.FILL_ENABLE_STATE: {
        return {
          ...state,
          enable: action.payload,
          loading: false,
        };
      }

      case actionTypes.GET_SHIPPING_PRICES: {
        return {
          ...state,
        };
      }

      case actionTypes.FILL_SHIPPING_PRICES: {
        return {
          ...state,
          shipping: action.payload,
        };
      }

      case actionTypes.SET_ERRORS: {
        return {
          ...state,
          errors: action.payload,
        };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  listQuotes: (value) => ({ type: actionTypes.LIST_QUOTES, payload: value }),
  listDetailQuote: (value) => ({
    type: actionTypes.LIST_DETAIL_QUOTE,
    payload: value,
  }),

  fillQuoteList: (value) => ({
    type: actionTypes.FILL_QUOTE_LIST,
    payload: value,
  }),
  fillQuoteDetail: (value) => ({
    type: actionTypes.FILL_QUOTE_DETAIL,
    payload: value,
  }),
  fillQuotePaginationData: (value) => ({
    type: actionTypes.FILL_QUOTE_PAGINATION_DATA,
    payload: value,
  }),
  confirmMyQuotesQuote: (value) => ({
    type: actionTypes.CONFIRM_MY_QUOTES_QUOTE,
    payload: value,
  }),
  claimMyQuotesQuote: (value) => ({
    type: actionTypes.CLAIM_MY_QUOTES_QUOTE,
    payload: value,
  }),
  result: (value) => ({
    type: actionTypes.SET_RESULT,
    payload: value,
  }),
  getRegions: (value) => ({
    type: actionTypes.GET_REGIONS,
    payload: value,
  }),
  fillRegions: (value) => ({
    type: actionTypes.FILL_REGIONS,
    payload: value,
  }),
  getImportQuote: (value) => ({
    type: actionTypes.GET_IMPORT_QUOTE,
    payload: value,
  }),
  fillImportQuote: (value) => ({
    type: actionTypes.FILL_IMPORT_QUOTE,
    payload: value,
  }),
  getExportQuote: (value) => ({
    type: actionTypes.GET_EXPORT_QUOTE,
    payload: value,
  }),
  fillExportQuote: (value) => ({
    type: actionTypes.FILL_EXPORT_QUOTE,
    payload: value,
  }),
  editMyQuotesPrice: (value) => ({
    type: actionTypes.EDIT_MY_QUOTES_PRICE,
    payload: value,
  }),
  fillMyQuotePrice: (value) => ({
    type: actionTypes.FILL_MY_QUOTES_PRICE,
    payload: value,
  }),
  changeState: (value) => ({
    type: actionTypes.GET_CHANGE_STATE,
    payload: value,
  }),
  fillChangeState: (value) => ({
    type: actionTypes.FILL_CHANGE_STATE,
    payload: value,
  }),
  listCurrencies: (value) => ({
    type: actionTypes.LIST_CURRENCIES,
    payload: value,
  }),
  fillListCurrencies: (value) => ({
    type: actionTypes.FILL_LIST_CURRENCIES,
    payload: value,
  }),
  getCategories: (value) => ({
    type: actionTypes.LIST_CATEGORIES_QUOTES,
    payload: value,
  }),
  fillgetCategories: (value) => ({
    type: actionTypes.FILL_LIST_CATEGORIES_QUOTES,
    payload: value,
  }),
  pauseState: (value) => ({
    type: actionTypes.PAUSE_STATE,
    payload: value,
  }),
  fillPauseState: (value) => ({
    type: actionTypes.FILL_PAUSE_STATE,
    payload: value,
  }),
  enableState: (value) => ({
    type: actionTypes.ENABLE_STATE,
    payload: value,
  }),
  fillEnableState: (value) => ({
    type: actionTypes.FILL_ENABLE_STATE,
    payload: value,
  }),
  getShippingPrices: (value) => ({
    type: actionTypes.GET_SHIPPING_PRICES,
    payload: value,
  }),
  fillShippingPrices: (value) => ({
    type: actionTypes.FILL_SHIPPING_PRICES,
    payload: value,
  }),
  setErrors: (value) => ({
    type: actionTypes.SET_ERRORS,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(
    actionTypes.LIST_QUOTES,
    function* listQuotesSaga({ payload }) {
      try {
        const response = yield listQuotes(payload);
        const pagination = response.data.message.pagination || {};
        const { data } = response.data.message;
        if (response.status === 200) {
          yield put(actions.result(null));
          yield put(actions.fillQuoteList(data));
          yield put(actions.fillPauseState([]));
          yield put(actions.fillEnableState([]));
          yield put(actions.fillQuotePaginationData(pagination));
        }
      } catch (err) {
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.CONFIRM_MY_QUOTES_QUOTE,
    function* confirmMyQuotesQuoteSaga({ payload }) {
      try {
        const { data } = yield confirmMyQuotesQuote(payload);
        if (data.code === 200) {
          yield put(actions.result(true));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.CLAIM_MY_QUOTES_QUOTE,
    function* claimMyQuotesQuoteSaga({ payload }) {
      try {
        const { data } = yield claimMyQuotesQuote(payload);
        if (data.code === 200) {
          yield put(actions.result(true));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.LIST_DETAIL_QUOTE,
    function* listDetailQuoteSaga({ payload }) {
      try {
        const response = yield listDetailQuote(payload);

        const pagination = response.data.message.pagination || {};

        const data = response.data.message;

        if (response.status === 200) {
          yield put(actions.result(null));
          yield put(actions.fillQuoteDetail(data.data));
          yield put(actions.fillQuotePaginationData(pagination));
        }
      } catch (err) {}
    },
  );

  yield takeLatest(
    actionTypes.GET_REGIONS,
    function* getRegionsSaga({ payload }) {
      try {
        const response = yield getRegions(payload);
        const data = response.data.message;

        if (response.status === 200) {
          yield put(actions.fillRegions(data.data));
        }
      } catch (err) {}
    },
  );

  yield takeLatest(
    actionTypes.GET_IMPORT_QUOTE,
    function* getImportQuoteSaga({ payload }) {
      try {
        const response = yield getImportQuote(payload);

        const data = response.data.message;

        if (response.status === 200) {
          if(response.data.status === 'success') {
            yield put(actions.result("importSuccess"));
            yield put(actions.fillImportQuote(data.data));
            yield put(actions.setErrors([]));
          }
          if(response.data.status === 'fail') {
            yield put(actions.result("importSuccess"));
            yield put(actions.setErrors(response.data.error));
          }
        }
      } catch (err) {
        yield put(actions.result("generalErrorImport"));
      }
    },
  );

  yield takeLatest(
    actionTypes.GET_EXPORT_QUOTE,
    function* getExportQuoteSaga({ payload }) {
      try {
        const response = yield getExportQuote(payload);
        if (response.status === 200) {
          yield put(actions.fillExportQuote(response.data));
          yield put(actions.result("exportResult"));
        }
      } catch (err) {
      }
    },
  );

  yield takeLatest(
    actionTypes.EDIT_MY_QUOTES_PRICE,
    function* editMyQuotesPriceSaga({ payload }) {
      try {
        const response = yield editMyQuotesPrice(payload);

        const data = response;

        if (data.status === 200) {
          yield put(actions.result(true));
          yield put(actions.fillMyQuotePrice(data.status));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.GET_CHANGE_STATE,
    function* changeStateSaga({ payload }) {
      try {
        const response = yield changeState(payload);

        const data = response;

        if (data.status === 200) {
          yield put(actions.result(true));
          yield put(actions.fillChangeState(data.status));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.result(false));
      }
    },
  );
  yield takeLatest(
    actionTypes.LIST_CURRENCIES,
    function* listCurrenciesSaga({ payload }) {
      try {
        const { data } = yield listCurrencies(payload);

        const modifiedData = data.message.data.map((item) => {
          item.name = item.code;
          item.currency_id = item.id;
          return item;
        });

        if (data.code === 200) {
          yield put(actions.fillListCurrencies(modifiedData));
        }
      } catch (err) {
      }
    },
  );

  yield takeLatest(
    actionTypes.LIST_CATEGORIES_QUOTES,
    function* getCategoriesSaga({ payload }) {
      //    const selector = yield select(getState);
      try {
        const data = yield getCategories();
        const modifiedData = data.data.map((item) => {
          item.category_id = item.id;
          return item;
        });
        if (data.status === 200) {
          yield put(actions.fillgetCategories(modifiedData));
        } else {
          yield put(actions.fillgetCategories([]));
        }
      } catch (e) {
        yield put(actions.fillgetCategories([]));
      }
    },
  );

  yield takeLatest(
    actionTypes.PAUSE_STATE,
    function* pauseStateSaga({ payload }) {
      try {
        const response = yield pauseState(payload);

        const data = response;

        if (data.status === 200) {
          yield put(actions.result(true));
          yield put(actions.fillPauseState(true));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.ENABLE_STATE,
    function* enableStateSaga({ payload }) {
      try {
        const response = yield enableState(payload);

        const data = response;

        if (data.status === 200) {
          yield put(actions.result(true));
          yield put(actions.fillEnableState(true));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.GET_SHIPPING_PRICES,
    function* getShippingPricesSaga({ payload }) {
      try {
        const response = yield getShippingPrices(payload);
        const data = response.data.message;
        if (response.status === 200) {
          yield put(actions.fillShippingPrices(data));
        }
      } catch (err) {}
    },
  );
}
