import axios from 'axios';
import { urlsBase } from '../../../../config';
// import {COMPANIES_URL} from "../../../services/cart.crud";

export const ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders`;
export const MY_ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-sales`;
export const HISTORY_URL = `${urlsBase.base_url}/api/v1/dispatch-notes/dispatchNotesHistory`;
export const UPLOAD_NOTE_URL = `${urlsBase.base_url}/api/v1/dispatch-notes/upload-dispatch-note-provider`;
export const PDF_DOWNLOAD_URL = `${urlsBase.base_url}/api/v1/pdf-download`;

export function listSales(payload) {
  let requestOrders = `?`;

  if (payload && payload.page && payload.perPage) {
    requestOrders += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state.length !== 0) {
    requestOrders += `&state=${payload.state}`;
  }else{
    requestOrders += `&state=0`;
  }

  if (payload && payload.from && payload.from.length !== 0) {
    requestOrders += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to.length !== 0) {
    requestOrders += `&to=${payload.to}`;
  }

  if (payload && payload.tenant && payload.tenant.length !== 0) {
    requestOrders += `&tenant=${payload.tenant}`;
  }

  if (payload && payload.search_param) {
    requestOrders += `&search_param=${payload.search_param}`;
  }

  return axios.get(`${MY_ORDERS_URL}/${requestOrders}`);
}

export function listDetailSales({ order_id, tenant }) {
  return axios.get(`${MY_ORDERS_URL}/${order_id}?tenant=${tenant}`);
}

export function getHistory({ order_id, tenant }) {
  return axios.get(`${HISTORY_URL}/${order_id}?tenant=${tenant}`);
}

export function approveSales(payload) {
  return axios.put(
    `${ORDERS_URL}/send/${payload.item}?tenant=${payload.tenant}`,
    payload
  );
}

export function rejectSales(payload) { 
  return axios.put(
    `${ORDERS_URL}/cancel/${payload?.payload.item}?tenant=${payload?.payload.tenant}`,
    {
      outdated_price: payload?.payload.outdatedPrice,
      discontinued_product: payload?.payload.discontinuedProduct,
      out_of_stock: payload?.payload.outOfStock,
      other: payload?.payload.other,
      cancel_reason_other: payload?.payload.otherReasonsReject,
    }
  );
}


export function downloadDispatchNote(data) {
  let requestParams = `?`;
  
  if (data?.user_type && data?.user_type !== '') {
    requestParams += `user_type=${data.user_type}`;
  }
  
  if(data?.tenant && data?.tenant !== ''){
    requestParams += `&tenant=${data.tenant}`;
  }
  
  return axios.get(
    `${PDF_DOWNLOAD_URL}/downloadProviderDispatchNote/${data.internalCode}/${data.dispatchNoteNumber}${requestParams}`,
  );
}

export function downloadConfirmDispatchNote(data) {
  let requestParams = `?`;
  
  if (data?.user_type && data?.user_type !== '') {
    requestParams += `user_type=${data.user_type}`;
  }
  
  if(data?.tenant && data?.tenant !== ''){
    requestParams += `&tenant=${data.tenant}`;
  }
  
  return axios.get(
    `${PDF_DOWNLOAD_URL}/downloadConfirmDispatchNote/${data.internalCode}/${data.dispatchNoteNumber}${requestParams}`,
  );
}

export function downloadBuyOrderPdf(data) {
  let requestParams = `?`;
  
  if (data?.user_type && data?.user_type !== '') {
    requestParams += `user_type=${data.user_type}`;
  }
  
  if(data?.tenant && data?.tenant !== ''){
    requestParams += `&tenant=${data.tenant}`;
  }
  
  return axios.get(
    `${PDF_DOWNLOAD_URL}/downloadBuyOrderPdf/${data.internalCode}${requestParams}`,
  );
}

export function downloadGoodsReceiptPdf(data) {
  let requestParams = `?`;

  if (data?.user_type && data?.user_type !== '') {
    requestParams += `user_type=${data.user_type}`;
  }

  if(data?.tenant && data?.tenant !== ''){
    requestParams += `&tenant=${data.tenant}`;
  }

  return axios.get(
    `${PDF_DOWNLOAD_URL}/downloadGoodsReceiptPdf/${data.internalCode}/${data.dispatchNoteNumber}${requestParams}`
  );
}

export function checkGoodsReceiptPdfExist(data) {
  let requestParams = `?`;

  if (data?.user_type && data?.user_type !== '') {
    requestParams += `user_type=${data.user_type}`;
  }

  if(data?.tenant && data?.tenant !== ''){
    requestParams += `&tenant=${data.tenant}`;
  }

  return axios.get(
    `${PDF_DOWNLOAD_URL}/checkGoodReceipt/${data.internalCode}/${data.dispatchNoteNumber}${requestParams}`
  );
}

export function approveSalesUploadDispatchNote(payload) {
  return axios.post(`${UPLOAD_NOTE_URL}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
