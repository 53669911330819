import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { H5 } from "../../../design-system/headings/H5";
import { ButtonBekeu } from "../../../design-system/forms/ButtonBekeu";

export const LoginSuccessPassword = () => {
  return (
    <Flex
      flexDir={"column"}
      my={"2rem"}
      alignItems="center"
      justifyContent={"center"}
    >
      <H5>Correo enviado con éxito</H5>
      <Text fontWeight={"normal"} fontSize={"1rem"} textAlign="center" mt={"2rem"}>
        Revisá tu correo electrónico, enviamos un <br />
        enlace para restablecer tu contraseña
      </Text>
      <ButtonBekeu as={Link} to="/login" mt={"2rem"} w={"75%"}>
        Volver al inicio
      </ButtonBekeu>
    </Flex>
  );
};
