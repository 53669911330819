import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Link} from "@chakra-ui/react";
import { MenuButton } from "../MenuButton/MenuButton";
import "./MainMenu.scss";

const MainMenu = ({
  user,
  userType,
  userRoles,
  userPermissions,
  ml,
  proUser,
  ...props
}) => {
  const location = useHistory().location.pathname;
  const history = useHistory();

  function handleClick(e, redirectPage) {
    e.preventDefault();
    history.push(redirectPage);
  }

  return (
    <Flex {...props}>

      {userType === "2" && (
        <>
          {userPermissions && !userPermissions.includes("client-approver") && !userPermissions.includes("credentials-sap") &&
          <Link href="/my-orders" style={{ textDecoration: "none" }}>
            <MenuButton
              variant={"ghost"}
              bg={"brand.primary"}
              colorScheme={"blackAlpha"}
              color={"brand.white"}
              ml={ml}
              isActive={location.includes("/my-orders")}
              onClick={(e) => handleClick(e, "/my-orders")}
            >
              Mis compras
            </MenuButton>
          </Link>
          }
          {userPermissions && userPermissions.includes("client-approver") &&
            <Link href="/approve-carts" style={{ textDecoration: "none" }}>
              <MenuButton
                variant={"ghost"}
                bg={"brand.primary"}
                colorScheme={"blackAlpha"}
                color={"brand.white"}
                ml={2}
                isActive={location.includes("/approve-carts")}
                onClick={(e) => handleClick(e, "/approve-carts")}
              >
                Aprobar compras
              </MenuButton>
            </Link>
          }
        </>
      )}

      {userType === "3" && (
        <Link
          href="/sales/?page=1&per_page=20&tenant=tenant"
          style={{ textDecoration: "none" }}
        >
          <MenuButton
            variant={"ghost"}
            bg={"brand.primary"}
            colorScheme={"blackAlpha"}
            color={"brand.white"}
            ml={"-0.75rem"}
            isActive={location.includes("/sales")}
            onClick={(e) =>
              handleClick(e, "/sales/?page=1&per_page=20&tenant=tenant")
            }
          >
            Mis ventas
          </MenuButton>
        </Link>
      )}

      {userType === "3" && (
        <Link
          href="/quotes?state=1&page=1&per_page=20"
          style={{ textDecoration: "none" }}
        >
          <MenuButton
            variant={"ghost"}
            bg={"brand.primary"}
            colorScheme={"blackAlpha"}
            color={"brand.white"}
            ml={2}
            isActive={location.includes("/quotes")}
            onClick={(e) =>
              handleClick(e, "/quotes?state=1&page=1&per_page=20")
            }
          >
            Mis cotizaciones
          </MenuButton>
        </Link>
      )}

      {userType === "2"
        && ((userRoles && userRoles.includes("client-whitelist-admin")) || (userPermissions && userPermissions.includes("provider-company-list")))
        && (
          <Link href="/my-providers" style={{ textDecoration: "none" }}>
            <MenuButton
              variant={"ghost"}
              bg={"brand.primary"}
              colorScheme={"blackAlpha"}
              color={"brand.white"}
              ml={2}
              isActive={location.includes("/my-providers")}
              onClick={(e) => handleClick(e, "/my-providers")}
            >
              Mis proveedores
            </MenuButton>
          </Link>
        )
      }

      {userType === "2"
        && ((userRoles && userRoles.includes("client-credentials-sap")) && (userPermissions && userPermissions.includes("credentials-sap")))
        && (
          <Link href="/credentials-sap" style={{ textDecoration: "none" }}>
            <MenuButton
              variant={"ghost"}
              bg={"brand.primary"}
              colorScheme={"blackAlpha"}
              color={"brand.white"}
              ml={2}
              isActive={location.includes("/credentials-sap")}
              onClick={(e) => handleClick(e, "/credentials-sap")}
            >
              Credenciales SAP
            </MenuButton>
          </Link>
        )
      }

      {userType === "3" && (
        <Link
          href="/shipping?state=1&page=1&per_page=20"
          style={{ textDecoration: "none" }}
        >
          <MenuButton
            variant={"ghost"}
            bg={"brand.primary"}
            colorScheme={"blackAlpha"}
            color={"brand.white"}
            ml={2}
            isActive={location.includes("/shipping")}
            onClick={(e) =>
              handleClick(e, "/shipping?state=1&page=1&per_page=20")
            }
          >
            Mis envíos
          </MenuButton>
        </Link>
      )}
    </Flex>
  );
};

export default MainMenu;
