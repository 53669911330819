import React, { useState } from "react";
import "./LoginPage.css";
import bekeuLogo from "../login/bekeuLogo.png";
import azure from "../login/assets/azure.png";
import { LoginInput } from "./Components/LoginInput";
import { Box, Flex, Image, Img, Text } from "@chakra-ui/react";
import { LoginButton } from "./Components/LoginButton";
import { login } from "./crud/auth.crud";
import * as auth from "./ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import useQuery from "../../hooks/useQuery";
import { loginRequest } from "../../../AuthConfig";
import { callMsGraph } from "../../../graph";
import { useMsal } from "@azure/msal-react";


const LoginPage = (props) => {
  //email and password states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState(2);
  const [incorrectEmailValidation, setIncorrectEmailValidation] = useState(false);

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const query = useQuery();

  function requestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response),
        );
      });
  }


  const handleSingleSignOn = (loginType = "popup") => {
    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then((res) => {
          login(res.account.username, "0", "0", res.accessToken)
            .then(({ data }) => {
              if (data.message.user?.password_changed_at === null) {
                props.login({
                  authToken: data.message.authorisation?.token,
                  resetToken: data.message.actionables?.token,
                  resetPassword: true,
                  user: data.message?.user,
                });
                props.history.push(
                  `/create-password?email=${data.message.user.email}&token=${data.message.actionables.token}`,
                );
                props.setLoading(false);
              } else {
                props.login({ authToken: data.message.authorisation?.token });
                props.fulfillUser(data.message?.user);
                props.setLoading(false);
                setIncorrectEmailValidation(true);
              }
            })
            .catch((err) => {
              props.setLoading(false);
              setIncorrectEmailValidation(true);
            });
        })
        .catch((e) => {
          props.setLoading(false);
          setIncorrectEmailValidation(true);
        });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
      });
    }
  };

  // setting email and password
  const handleValidationEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleValidationPassword = (e) => {
    setPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };

  const handleValidationUserType = (type) => {
    setUserType(type);
  };

  const handleLogin = async (e) => {
    try {
      props.setLoading("Loading");
      setIncorrectEmailValidation(false);

      const { data } = await login(email, password, userType);
      const { user, authorisation, actionables } = data.message;

      if (user.password_changed_at === null) {
        props.login({
          authToken: authorisation?.token,
          resetToken: actionables?.token,
          resetPassword: true,
          user,
        });
        props.setLoading(false);
        props.history.push(
          `/create-password?email=${user.email}&token=${actionables.token}`,
        );
      } else {
        props.login({ authToken: authorisation?.token });
        props.fulfillUser(user);
        props.setLoading(false);
        setIncorrectEmailValidation(true);
      }
    } catch (err) {
      console.error("Error during login:", err);
      props.setLoading(false);
      setIncorrectEmailValidation(true);
    }
  };

  //email and password validation states
  const emailValidation = email.length < 8 && email.length !== 0 ? true : false;
  const passwordValidation =
    password.length < 8 && password.length !== 0 ? true : false;
  const buttonValidation =
    email.length > 0 && email !== "" && password.length > 0 && password !== "";

  return (
    <>
      <Flex
        bg={"brand.background"}
        h={"100vh"}
        justifyContent={"center"}
        alignItems={"center"}
        className="fade"
        p={["1rem","unset","unset","unset"]}
        flexDir={"column"}
      >
       
          <Flex justifyContent={"center"}>
              <Img src={bekeuLogo} alt="bekeu logo" h={"fit-content"} />
          </Flex>

          <Flex flexDir={"column"} alignItems={"center"} textAlign={"center"} fontSize={"1.5rem"} m={"1.5rem"} w={"100%"}>
            <Text fontWeight={"normal"} lineHeight="2rem">
              Te damos la bienvenida a 
            </Text>
            <Text fontWeight={"semibold"} lineHeight="2rem">
            Bekeu Marketplace
            </Text>
          </Flex>
          
          <Box
            bg={"brand.white"}
            maxW={"27.438rem"}
            w={"100%"}
            borderWidth="0.0625rem"
            borderRadius="lg"
            overflow="hidden"
            shadow={"md"}
            mt={"2rem"}
          >
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              minH={process.env.REACT_APP_SSO_ENABLE === "true" ? "11rem" : "25.18rem"}
            >

          {(process.env.REACT_APP_SSO_ENABLE === "false" || process.env.REACT_APP_SSO_ENABLE === undefined) && (
            <>
              <Flex flexDir={"column"} w={"75%"} alignItems={"center"} mt={"2rem"}>
                <LoginInput
                  placeholder="Email"
                  display={"none"}
                  isInvalid={emailValidation || incorrectEmailValidation}
                  value={email}
                  onChange={handleValidationEmail}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
                <LoginInput
                  placeholder="Contraseña"
                  isInvalid={emailValidation || incorrectEmailValidation}
                  onChange={handleValidationPassword}
                  isPassword={true}
                  value={password}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
                <MenuBekeu
                  items={[
                    { id: 2, name: "Comprador" },
                    { id: 3, name: "Proveedor" },
                  ]}
                  fontWeight={400}
                  borderRadius={"0.75rem"}
                  height={50}
                  customOnClick={handleValidationUserType}
                  isInvalid={emailValidation || incorrectEmailValidation}
                >
                  Comprador
                </MenuBekeu>

                {incorrectEmailValidation && (
                  <Text
                    color={"brand.error"}
                    fontSize={"0.75rem"}
                    fontWeight={300}
                    mt="0.75rem"
                    className="animate__animated animate__fadeIn"
                  >
                    Revisá los datos ingresados.
                  </Text>
                )}
              </Flex>
              <Flex flexDir={"column"} w={"100%"} alignItems="center" m={"2rem"}>
                <LoginButton
                  isLoading={props.isLoading}
                  isDisabled={!buttonValidation}
                  onClick={handleLogin}
                  text="Ingresar"
                />
                <ButtonBekeuLink
                  onClick={() => props.history.push("/forgot-password")}
                  mt={"2rem"}
                >
                  ¿Olvidaste tu contraseña?
                </ButtonBekeuLink>
              </Flex>
            </>
            )
          }

          {process.env.REACT_APP_SSO_ENABLE === "true" && (
              <Flex flexDir={"column"} w={"100%"} alignItems="center" m={"2rem"}>
                <Flex alignItems="center">
                  <Image src={azure} boxSize="2rem" mr="0.5rem" mb={"1rem"}/>
                  <Text fontSize={"1.25rem"} fontWeight={"semibold"} lineHeight={"1.70rem"} mb={"1rem"}>
                    Ingresar con Azure
                  </Text>
                </Flex>
                <LoginButton
                  isLoading={props.isLoading}
                  onClick={() => handleSingleSignOn()}
                  text="Ingresar"
                  style={{
                    marginRight: "0rem !important"
                  }}
                />
              </Flex>
            )
          }

          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(LoginPage));
