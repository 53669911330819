import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  List,
  ListItem,
} from "@chakra-ui/react";

const MyQuotesImportErrorModal = ({ isOpen, onClose, errors }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
        <ModalBody>
          {errors.length > 0 ? (
            <Box
              maxHeight="12.5rem"
              overflowY={errors.length > 5 ? "scroll" : "hidden"}
              borderColor="gray.300"
              borderRadius="md"
              padding="0.625rem"
              margin="2rem"
              className={"overflow-y"}
            >
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Se produjeron los siguientes {errors.length} errores:
              </Text>
              <List spacing={1}>
                {errors.map((error, index) => (
                  <ListItem key={index} color="red.500">
                    {error}
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Text>No se han encontrado errores</Text>
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MyQuotesImportErrorModal;
