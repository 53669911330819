import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import arrowRight from "../../design-system/icons/Arrows/arrow-right.svg";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

export const MyQuotesBreadcrumb = () => {
  const location = useLocation().pathname;
  const params = useParams();
  const history = useHistory();

  return (
    <Breadcrumb spacing="8px" separator={<BekeuIcon src={arrowRight} />}>
      <BreadcrumbItem>
        <BreadcrumbLink
          onClick={() => history.push("/")}
          _hover={{ color: "brand.hover" }}
          fontFamily={"Open Sans"}
          fontWeight={400}
          fontSize={"14px"}
        >
          Inicio
        </BreadcrumbLink>
      </BreadcrumbItem>
      {location.includes("quotes") && (
        <BreadcrumbItem>
          <BreadcrumbLink
            color={location === "/quotes" && "brand.primary"}
            onClick={() => history.push("/quotes?state=1&page=1&per_page=20")}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Mis cotizaciones
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {location.includes("quotes/quotedetailzone") && (
        <BreadcrumbItem>
          <BreadcrumbLink
            color={location === "/quotes/quotedetailzone" && "brand.primary"}
            onClick={() => history.push("/quotes/quotedetailzone")}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Precios de envío
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};

export default MyQuotesBreadcrumb;
