import React from "react";
import { Flex, Box} from "@chakra-ui/react";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { useHistory } from "react-router-dom";
import { H5 } from "../../design-system/headings/H5";


export const NotFoundPageToken = () => {
  const history = useHistory();
  return (
    <Flex bg={"brand.background"} justifyContent="center" borderRadius="lg">
      <Box
        bg={"brand.white"}
        w={"471px"}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
          minH={"12.5rem"}
        >
          <H5>El link ha expirado</H5>
          <ButtonBekeu w={"50%"} onClick={() => history.push("/forgot-password")}>
            Obtener un nuevo link
          </ButtonBekeu>
        </Flex>
      </Box>
    </Flex>
  );
};
