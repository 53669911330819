import { Flex, Button } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import arrowLeft from "../../design-system/icons/Arrows/arrow-left.svg";
import arrowRight from "../../design-system/icons/Arrows/white-arrow-right.svg";
import useQuery from "../../hooks/useQuery";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { usePagination } from "@material-ui/lab/Pagination";
import { INITIAL_STORAGED_FILTERS } from "../../constants/constants";

export const Paginator = ({ customOnClick, currentPage, ...props }) => {
  //hooks
  const history = useHistory();
  const query = useQuery();

  //useQuery variables
  const page = Number(query.get("page"));
  const perPage = query.get("per_page");
  const term = query.get("term");
  const state = query.get("state");
  const category_id = Number(query.get("category_id"));
  const tenant = query.get("tenant");
  const search_param = query.get("search_param");
  const provider = query.get("provider");
  const from = query.get("from");
  const to = query.get("to");
  const d = query.get("d");

  // localStorage variables
  const globalStorageVar = localStorage.getItem("globalStorage");
  const globalStorage = JSON.parse(globalStorageVar);

  // setting global state to null. Affects products grid filter box
  function setGlobalStorage() {
    localStorage.setItem(
      "globalStorage",
      JSON.stringify({
        isFirstLoad: true,
        page: null,
      }),
    );
  }

  // function for scrolling to top
  function setScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  // function for setting storage filters
  function setStorageFilters() {
    const filtersLS = JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS;
    localStorage.setItem("storageFilters", JSON.stringify({...filtersLS, resetPage:false}));
  }

  function handleClick() {
    setScrollTo();
    setGlobalStorage();
    setStorageFilters();
  }

  const { items } = usePagination({
    count: props.totalItems,
    page: page,
  });

  // Concat query params variables
  let searchLeft = ``;

  if (page) {
    searchLeft += `?page=${page - 1}`;
  }

  if (perPage) {
    searchLeft += `&per_page=${perPage}`;
  }

  if (state) {
    searchLeft += `&state=${state}`;
  }

  if (term) {
    searchLeft += `&term=${term}`;
  }

  if (category_id) {
    searchLeft += `&category_id=${category_id}`;
  }

  if (tenant) {
    searchLeft += `&tenant=${tenant}`;
  }

  if (search_param) {
    searchLeft += `&search_param=${search_param}`;
  }

  if (provider && provider !== "0") {
    searchLeft += `&provider=${provider}`;
  }

  if (from) {
    searchLeft += `&from=${from}`;
  }

  if (to) {
    searchLeft += `&to=${to}`;
  }

  if (d) {
    searchLeft += `&d=${d}`;
  }

  let searchRight = ``;

  if (page) {
    searchRight += `?page=${page + 1}`;
  }

  if (perPage) {
    searchRight += `&per_page=${perPage}`;
  }

  if (state) {
    searchRight += `&state=${state}`;
  }

  if (term) {
    searchRight += `&term=${term}`;
  }

  if (category_id) {
    searchRight += `&category_id=${category_id}`;
  }

  if (tenant) {
    searchRight += `&tenant=${tenant}`;
  }

  if (search_param) {
    searchRight += `&search_param=${search_param}`;
  }

  if (provider && provider !== "0") {
    searchRight += `&provider=${provider}`;
  }

  if (from) {
    searchRight += `&from=${from}`;
  }

  if (to) {
    searchRight += `&to=${to}`;
  }

  if (d) {
    searchRight += `&d=${d}`;
  }

  return (
    <Flex w={"100%"} justifyContent="center" mt={"1.5rem"}/*{...props}*/>
      <ul
        style={{
          listStyle: "none",
          padding: 10,
          margin: 0,
          display: "flex",
        }}
      >
        {items.map(({ page, type, selected, count, ...item }, index) => {
          
          let children = null;
          let search = `?page=${page}`;

          if (perPage) {
            search += `&per_page=${perPage}`;
          }

          if (state) {
            search += `&state=${state}`;
          }

          if (term) {
            search += `&term=${term}`;
          }

          if (category_id) {
            search += `&category_id=${category_id}`;
          }

          if (tenant) {
            search += `&tenant=${tenant}`;
          }

          if (search_param) {
            search += `&search_param=${search_param}`;
          }

          if (provider) {
            search += `&provider=${provider}`;
          }

          if (from) {
            search += `&from=${from}`;
          }

          if (to) {
            search += `&to=${to}`;
          }

          if (d) {
            search += `&d=${d}`;
          }

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <Button
                mx={"0.5rem"}
                _active={{ bg: "brand.primary", color: "white" }}
                size={"sm"}
                isActive={query.get("page") === `${page}`}
                _focus={{
                  boxShadow:
                    "0 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
                }}
                cursor="context-menu"
                isDisabled
              >
                ...
              </Button>
            );
          } else if (type === "page") {
            children = (
              <Button
                mx={"0.5rem"}
                _active={{ bg: "brand.primary", color: "white" }}
                size={"sm"}
                isActive={
                  globalStorage?.page === page ||
                  (!globalStorage?.page && query.get("page") === `${page}` || !globalStorage?.page && currentPage === page )
                }
                _focus={{
                  boxShadow:
                    "0 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
                }}
                {...item}
                onClick={() => {
                  if (!customOnClick) {
                    handleClick();
                    query.get("page") !== `${page}` && history.push(search);
                  } else {
                    handleClick();
                    query.get("page") !== `${page}` && customOnClick(page);
                  }
                }}
              >
                {page}
              </Button>
            );
          } else {
            children =
              type === "previous" ? (
                <Button
                  mx={"0.5rem"}
                  bg="brand.primary"
                  color="white"
                  size={"sm"}
                  _focus={{
                    boxShadow:
                      "0 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
                  }}
                  _hover={{ bg: "brand.hover" }}
                  {...item}
                  disabled={currentPage ? currentPage - 1 === 0 : item?.disabled}
                  onClick={() => {
                    handleClick();
                    if(customOnClick){
                      customOnClick(currentPage - 1);
                    } else {
                      history.push(searchLeft);
                    }
                  }}
                >
                  <BekeuIcon src={arrowLeft} />
                </Button>
              ) : (
                <Button
                  mx={"0.5rem"}
                  bg="brand.primary"
                  color="white"
                  size={"sm"}
                  _focus={{
                    boxShadow:
                      "0 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
                  }}
                  _hover={{ bg: "brand.hover" }}
                  {...item}
                  disabled={currentPage ? currentPage === props?.totalItems : item?.disabled }
                  onClick={() => {
                    handleClick();
                    if(customOnClick){
                      customOnClick(currentPage + 1);
                    } else {
                      history.push(searchRight);
                    }
                  }}
                >
                  <BekeuIcon src={arrowRight} />
                </Button>
              );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </Flex>
  );
};
