import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";

import moment from "moment";

import {
  Flex,
  SimpleGrid,
  Text,
  useDisclosure,
  Box,
  Grid,
  GridItem,
  Img,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";

import search from "../../design-system/icons/Search/search-normal.svg";
import calendarIcon from "../../design-system/icons/Essentials/calendar.svg";

import MyOrdersItem from "../../components/MyOrdersItem/MyOrdersItem";
import MyOrdersItemLoader from "../../components/MyOrdersItem/MyOrdersItemLoader";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { Paginator } from "../../components/Paginator/Paginator";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";

import { actions as orderActions } from "./ducks/myOrders.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import { actions as cartActions } from "../../store/cart/cart.duck";

import useFeatures from "../../hooks/useFeatures";

const MyOrdersPage = ({ ...props }) => {
  const ordersState = useSelector((state) => state.orders);
  const cartsState = useSelector((state) => state.carts);
  const [filteredItems, setFilteredItems] = useState([]);
  const [providerList, setProviderList] = useState([
    {
      name: "Todos",
      provider: "0",
    },
  ]);
  const [hasError, setHasError] = useState("");

  const [d, setD] = useState(null);
  const [date, setDate] = useState({});
  const [state, setState] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [searchParamInput, setSearchParamInput] = useState("");
  const [search_param, setSearchParam] = useState("");
  const [provider, setProvider] = useState("");
  const [totalErrors, setTotalErrors] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenTotalErrors, onOpen: onOpenTotalErrors, onClose: onCloseTotalErrors } = useDisclosure();
  const { sapUser, shippingIncludedUser, dispatchNoteUser } = useFeatures();

  const arr = [...new Array(ordersState.ordersPagination.totalPages)];

  useEffect(() => {
    props.getCart();
    setTotalErrors([]);
  }, []);

  useEffect(() => {
    handleListCarts();
  }, [currentPage, state, provider, search_param, d])

  useEffect(() => {
    if (cartsState?.carts && cartsState?.carts.length > 0) {
      setFilteredItems(cartsState?.carts);
    } else {
      setFilteredItems([]);
    }
  }, [cartsState.carts]);

  useEffect(() => {
    if(cartsState?.providerList && cartsState?.providerList.length > 0 && isFirstLoad){ 
      setProviderList(cartsState.providerList);
      setIsFirstLoad(false);
    } 
  }, [cartsState.providerList, provider]);

  useEffect(() => {
    if (totalErrors.length > 0) {
      onOpenTotalErrors();
    }
  }, [totalErrors]);

  const handleListCarts = () => {
    props.fillOrderList([]);
    props?.listCarts({
      page: currentPage,
      perPage,
      state,
      provider,
      search_param,
      date,
    });
  }

  const handleChangeProvider = (id, obj) => {
    setProvider(obj.provider);
    setCurrentPage(1);
  }

  const handleChangeState = (id, obj) => {
    setState(obj.state);
    setCurrentPage(1);
  }

  const handleChangeSearchParam = (value) => {
    setSearchParamInput(value)

    if (value.length > 2) {
      setSearchParam(value);
      setCurrentPage(1);
      handleListCarts();
      return
    }
    if (value.length === 0) {
      setSearchParam("");
      setCurrentPage(1);
      handleListCarts();
      return
    }
  };

  // Date filter
  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });
  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment().add(-24, "M").format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment().startOf("month").format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment().add(-3, "M").format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment().add(-6, "M").format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment().add(-12, "M").format("YYYY-MM-DD");
    }

    setDate({
      from: newDate,
      to: item.to,
      d: item.d,
    });

    setD(item.d.toString());

    setCurrentPage(1);
  };

  const handleChangeDate = (id, obj) => {
    setMoment(obj);
  }

  const handleChangePagination = (pageNumber) => {
    if (pageNumber === currentPage) return;
    setCurrentPage(pageNumber);
  }

  const handlePushErrors = (error) => {
    setTotalErrors((prev) => {
      return [...prev, error];
    });
  }

  return (
    <Flex justifyContent={"center"} paddingInline={4}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"2.5rem"}
        bg={"brand.background"}
        maxW={"75rem"}
        w={"100%"}
        px={["1rem", "1rem", "1rem", "0"]}
      >
        {/* TODO: es un modal provisorio en el caso de que haya algún problema con los montos en ARS o USD */}
        <ErrorModal isOpen={isOpenTotalErrors} onClose={onCloseTotalErrors} codeError={"totalError"} />
        <ApproveOrdersBreadcrumb />

        <Grid
          templateColumns={["unset", "unset", "unset", "20% auto"]}
          alignItems="center"
          justifyContent="space-between"
        >
          <GridItem>
            <H5>Mis compras</H5>
          </GridItem>

          <GridItem>
            <AlertBekeu my={"2rem"}>
              Recordá que una vez recibida tu compra debes confirmar la
              recepción.
            </AlertBekeu>
          </GridItem>
        </Grid>

        <SimpleGrid
          spacing={4}
          templateColumns={[
            "unset",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "40% repeat(3,1fr)",
          ]}
        >
          {/* Buscar */}
          <Box>
            <InputBekeu
              placeholder={sapUser ? "Buscar por N° de carrito / N° de pedido SAP / N° de compra" : "Buscar por N° de carrito / N° de compra"}
              bg={"white"}
              lefticon={<Img src={search} mr={4} />}
              value={searchParamInput}
              onChange={(e) => handleChangeSearchParam(e.target.value)}
            />
          </Box>

          {/* Estado */}
          <Box>
            <MenuBekeu
              height={"3.5rem"}
              items={[
                { name: "Todas", state: "0" },
                { name: "Pendiente de aprobación", state: "1" },
                { name: "En curso", state: "2" },
                { name: "Cancelada", state: "3" },
                { name: "Finalizada", state: "4" },
              ]}
              customOnClick={handleChangeState}
            >
              {state === "0"
                ? "Todas"
                : state === "1"
                  ? "Pendiente de aprobación"
                  : state === "2"
                    ? "En curso"
                    : state === "3"
                      ? "Cancelada"
                      : state === "4"
                        ? "Finalizada"
                        : "Estado"}
            </MenuBekeu>
          </Box>

          {/* Proveedor */}
          <Box>
            <MenuBekeu height={"3.5rem"} items={providerList} customOnClick={handleChangeProvider} itemListPerView={providerList.length > 3 ? 4 : providerList.length}>
              Proveedores
            </MenuBekeu>
          </Box>

          {/* Fecha */}
          <Box>
            <MenuBekeu
              height={"3.5rem"}
              leftIcon
              leftCustomIcon={calendarIcon}
              setMoment={setMoment}
              state={state}
              items={[
                {
                  name: "Todas",
                  date: "Todas",
                  to: formattedDate,
                  d: 0,
                },
                {
                  name: "Este mes",
                  date: "Este mes",
                  to: formattedDate,
                  d: 1,
                },
                {
                  name: "Últimos 3 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 3,
                },
                {
                  name: "Últimos 6 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 6,
                },
                {
                  name: "Últimos 12 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 12,
                },
              ]}
              customOnClick={handleChangeDate}
            >
              {d
                ? (d === "0" || d === 0)
                  ? "Todas"
                  : d === "1"
                    ? "Este mes"
                    : d === "3"
                      ? "Últimos 3 meses"
                      : d === "6"
                        ? "Últimos 6 meses"
                        : d === "12" && "Últimos 12 meses"
                : "Fecha"}
            </MenuBekeu>
          </Box>
          {/* <ButtonBekeuOutline
                onClick={() => history.push("/approve-orders/delegate-orders")}
                isDisabled
                height={"3.5rem"}
              >
                Exportar/Descargar
              </ButtonBekeuOutline> */}
        </SimpleGrid>

        {!cartsState.loading ? (
          <>
            {filteredItems && filteredItems.length > 0 ? (
              <>
                {filteredItems.map((item, id) => {
                  return (
                    <MyOrdersItem
                      mt={"2rem"}
                      type={item?.state}
                      key={id}
                      item={item}
                      status={item?.state === "pending" ? true : undefined}
                      sapAvailable={sapUser}
                      dispatchNoteAvailable={dispatchNoteUser}
                      shippingIncluded={shippingIncludedUser}
                      handlePushErrors={handlePushErrors}
                    />
                  );
                })}
              </>
            ) : (
              <Text mt={6} className="animate__animated animate__fadeIn">
                No se encontraron compras{" "}
                {state === "1"
                  ? "Pendiente de aprobación"
                  : state === "2"
                    ? "En curso"
                    : state === "3"
                      ? "Cancelada"
                      : state === "4" && "Finalizada"}
              </Text>
            )}
          </>
        ) : (
          [1, 2].map((item, id) => (
            <MyOrdersItemLoader mt={"2rem"} key={id} item={item} />
          ))
        )}
        {!cartsState.loading &&
          cartsState &&
          cartsState.cartsPagination.totalPages > 1 && (
            <Paginator
              itemsArray={arr}
              totalItems={ordersState.ordersPagination.totalPages}
              perPage={5}
              customOnClick={handleChangePagination}
              currentPage={currentPage}
            />
          )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...orderActions, ...product.actions, ...cartActions })(MyOrdersPage),
);
