import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Grid,
  GridItem,
  useToast,
  Img,
  Text,
  Box,
} from "@chakra-ui/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WhiteListBreadcrumb } from "../../components/WhiteListBreadcrumb/WhiteListBreadcrumb";
import { H5 } from "../../design-system/headings/H5";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { H6 } from "../../design-system/headings/H6";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import search from "../../design-system/icons/Search/search-normal.svg";
import helpCircle from "../../design-system/icons/Shipping/help-circle.svg";
import { ProviderItem } from "../../components/WhiteList/ProviderItem";
import { ProviderTitle } from "../../components/WhiteList/ProviderTitle";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
// import errCircle from "../../design-system/icons/Arrows/close-circle.svg";
import errCircle from "../../design-system/icons/Arrows/close-circle.svg";

import { ErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";

import { actions as providers } from "./ducks/ClientWhiteList.duck";

import { bindActionCreators } from "redux";

import { useSelector } from "react-redux";
import { useState } from "react";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { ClientLoader } from "../../components/WhiteList/ClientLoader";

const ClientWhiteListPage = ({
  listProviders,
  listProvidersWithResult,
  changeDeliveryTime,
  enableProvider,
  disableProvider,
  blockProvider,
  unblockProvider,
  updateSAPNumber,
  ...props
}) => {
  // const [menuButton, setMenuButton] = useState("Mas relevantes");
  const [loading, setLoading] = useState(false);
  const [percentageDisable, setPercentageDisable] = useState(false);
  const [providerInput, setProviderInput] = useState("");
  const [SAPNumber, setSAPNumber] = useState("");
  // const [providerTypeAction, setProviderTypeAction] = useState("");
  // const [percentage, setPercentage] = useState("");
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const {
    isOpen: isPauseOpen,
    onOpen: onPauseOpen,
    onClose: onPauseClose,
  } = useDisclosure();

  const mainState = useSelector((state) => state);

  useEffect(() => {
    listProviders();
  }, []);

  useEffect(() => {
    if (mainState?.providers?.result) {
      listProviders();
    }

    if(mainState?.providers?.result === false){
      toast({
        title: "Ocurrío un error",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        render: () => (
          <Box
            color="brand.primary"
            height={"3rem"}
            p={3}
            bg="#F44336"
            borderRadius={"0.125rem"}
            borderTopLeftRadius={"0.75rem"}
          >
            <Flex>
              <Img src={errCircle} px={"0.5rem"} filter={"invert(100%)"} />
              <Text px={"0.5rem"} color="white">
                Ocurrió un error al realizar la acción
              </Text>
            </Flex>
          </Box>
        ),
      });
    }
  }, [mainState.providers.result]);

  /*useEffect(() => {
    if (mainState.providers.deliveryTimeResult) {
      listProvidersWithResult();
    }
  }, [mainState.shipping.deliveryTimeResult, listProvidersWithResult]);
*/
  function handleEnable(item) {
    enableProvider({ company_id: item });
  }

  function handleDisable(item) {
    disableProvider({ company_id: item });
  }

  function handleBlock(item) {
    blockProvider({ company_id: item });
  }

  function handleUnblock(item) {
    unblockProvider({ company_id: item });
  }

  function handleMassiveAction() {
    setLoading(true);
    /*shippingTypeAction === "activate"
      ? props.massiveActivate()
      : shippingTypeAction === "pause" && props.massivePause();*/
  }

  function handleChangeInternalCode(SAPNumber, inputId) {
    setSAPNumber(SAPNumber);
  }

  function handleSaveInternalCode(item) {
    const obj = {
      internal_code: SAPNumber,
      company_id: item,
    };
    updateSAPNumber(obj);
    handleChangeInternalCode("");
  }

  const ProviderTitleTemplate = [
    {
      id: 1,
      enable_client: "Activar / Desactivar",
      client_block: "Bloquear / Desbloquear",
      business_name: "Razón social",
      alias: "Alias",
      payment_condition: "Condición de Pago",
      company_group: "Pertenece al grupo",
      internal_code: "N° del proveedor",
      provider_id: "# Compañía",
      state: "Estado",
      active: "Activo",
      blocked: "Bloqueado",
      cuit: "CUIT",
    },
  ];

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"2.5rem"}
        bg={"brand.background"}
        w="75rem"
        /*{...props}*/
      >
        <WhiteListBreadcrumb />
        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
          my={"2rem"}
          alignItems={"center"}
          placeItems={["center", "unset"]}>
          <H5>Administración de proveedores</H5>

          <GridItem justifySelf={["center", "flex-end"]} mt={["1rem", "unset"]}>
            <AlertBekeu>
              Recordá activar los proveedores con los que querés operar.
            </AlertBekeu>
          </GridItem>
        </Grid>
        {mainState?.providers?.loading ? (
          <ErrorBoundary fallback={<p>Something went wrong at Loading</p>}>
            <BoxBekeu w="100%" mt={"2rem"} p="0">
              <Flex justifyContent={"space-between"} px="1.5rem">
                <H6 py="2.43rem">Proveedores Disponibles</H6>
                <Flex alignItems={"center"}>
                  <InputBekeu
                    placeholder={"Buscar"}
                    bg={"white"}
                    lefticon={<BekeuIcon src={search} mr={4} />}
                    mr={"2rem"}
                    groupw="18.75rem"
                    value={providerInput}
                    onChange={(e) => setProviderInput(e.target.value)}
                  />
                  {/* <Menu>
                    <MenuButton
                      as={Button}
                      bg="#FFFFFF"
                      border="0.093rem solid"
                      borderColor={"brand.contentSecondary"}
                      borderRadius="0.31rem"
                      m="0 0.75rem"
                      height="1.25rem"
                      minWidth="1.25rem"
                      padding="0 0 0.56rem 0"
                      transform="rotate(90deg)"
                      _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}>
                      ...
                    </MenuButton>
                  </Menu> */}
                </Flex>
              </Flex>
              <Divider />
              {ProviderTitleTemplate.map((provider, id) => (
                <ProviderTitle
                  key={provider.id}
                  item={provider}
                  infobtn={
                    <Button
                      variant={"ghost"}
                      borderRadius={"0.75rem"}
                      m={2}
                      p={0}
                      _focus={{
                        boxShadow: "rgba(0, 0, 0, 0)",
                      }}
                      onClick={onOpen}>
                      <BekeuIcon src={helpCircle} />
                    </Button>
                  }
                />
              ))}

              <Grid my={5} justifyContent={"center"}>
                <CircularProgress color={"inherit"} />
              </Grid>
            </BoxBekeu>
          </ErrorBoundary>
        ) : (
          <ErrorBoundary
            fallback={<p>Something went wrong Loaded Providers</p>}>
            <BoxBekeu w="100%" mt={"2rem"} p="0">
              <Flex justifyContent={"space-between"} px="1.5rem">
                <H6 py="2.43rem">Proveedores Disponibles</H6>
                <Flex alignItems={"center"}>
                  <InputBekeu
                    placeholder={"Buscar"}
                    bg={"white"}
                    lefticon={<BekeuIcon src={search} mr={4} />}
                    mr={"2rem"}
                    groupw="18.75rem"
                    value={providerInput}
                    onChange={(e) => setProviderInput(e.target.value)}
                  />
                  <ErrorBoundary fallback={<p>Something went wrong Menu</p>}>
                    <Menu>
                      {/* <MenuButton
                        as={Button}
                        bg="#FFFFFF"
                        border="0.093rem solid"
                        borderColor={"brand.contentSecondary"}
                        borderRadius="0.31rem"
                        m="0 0.75rem"
                        height="1.25rem"
                        minWidth="1.25rem"
                        padding="0 0 0.56rem 0"
                        transform="rotate(90deg)"
                        _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}>
                        ...
                      </MenuButton> */}

                      <Modal
                        isOpen={isPauseOpen}
                        onClose={onPauseClose}
                        size="xl"
                        isCentered>
                        <ModalOverlay />
                        <ModalContent
                          borderRadius="0.75rem"
                          fontFamily={"Open Sans"}
                          flexDir={"row"}>
                          <ModalCloseButton
                            _focus={{ boxShadow: "rgba(0,0,0,0)" }}
                          />
                          {mainState?.providers?.result ? (
                            <ModalBody
                              borderRadius={"0.75rem"}
                              m={"1.25rem"}
                              textAlign="center"
                              p="1.5rem">
                              <Flex flexDir={"column"} alignItems={"center"}>
                                <BekeuIcon src={success} mb={"1.5rem"} />
                                <H5 mb={"1.5rem"}>
                                  Cambios realizados con éxito
                                </H5>
                                <ButtonBekeu
                                  mr="0.75rem"
                                  onClick={onPauseClose}
                                  my={0}
                                  px="4.375rem"
                                  w="fit-content">
                                  Aceptar
                                </ButtonBekeu>
                              </Flex>
                            </ModalBody>
                          ) : (
                            <ModalBody
                              borderRadius={"0.75rem"}
                              m={"1.25rem"}
                              textAlign="center"
                              p="1.5rem">
                              <H5 mb={"1.5rem"}>
                                {/*shippingTypeAction === "pause"
                              ? `¿Estás seguro que querés pausar
                            todas tus zonas de entrega?`
                              : shippingTypeAction === "activate" &&
                                `¿Estás seguro que querés activar
                              todas tus zonas de entrega?`*/}
                              </H5>
                              <Paragraphs mb={"1.5rem"} lineHeight="1.275rem">
                                {/*shippingTypeAction === "pause"
                              ? "Al pausar todas tus zonas de entrega tus productos no estarán disponibles para comprar."
                              : shippingTypeAction === "activate" &&
                                "Al activar todas tus zonas de entrega tus productos estarán disponibles para comprar."*/}
                              </Paragraphs>
                              <Flex w="100%">
                                <ButtonBekeuOutline
                                  mr="0.75rem"
                                  onClick={onPauseClose}>
                                  No, Cancelar
                                </ButtonBekeuOutline>
                                <ButtonBekeu
                                  my="0"
                                  ml="0.75rem"
                                  onClick={handleMassiveAction}
                                  isLoading={loading}>
                                  Si, Confirmar
                                </ButtonBekeu>
                              </Flex>
                            </ModalBody>
                          )}
                        </ModalContent>
                      </Modal>

                      {/* <MenuList bg={"white"} borderRadius={"10.31rem"} py={"0.75rem"}>
                        <MenuItem
                          _focus={{ bg: "brand.transparent" }}
                          p={"0.75rem 1.5rem"}
                          _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                          bgColor="white"
                          onClick={() => {
                            onPauseOpen();
                            setShippingTypeAction("activate");
                          }}>
                          Activar masivamente
                        </MenuItem>
                        <MenuItem
                          _focus={{ bg: "brand.transparent" }}
                          p={"0.75rem 1.5rem"}
                          _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                          bgColor="white"
                          onClick={() => {
                            onPauseOpen();
                            setShippingTypeAction("pause");
                          }}>
                          Pausar masivamente
                        </MenuItem>
                        </MenuList> */}
                    </Menu>
                  </ErrorBoundary>
                </Flex>
              </Flex>
              <Divider />
              <ErrorBoundary
                fallback={<p>Something went wrong ProviderTitle</p>}>
                {ProviderTitleTemplate.map((provider, id) => (
                  <ProviderTitle
                    key={provider.id}
                    item={provider}
                    infobtn={
                      <Button
                        variant={"ghost"}
                        borderRadius={"0.75rem"}
                        m={2}
                        p={0}
                        _focus={{
                          boxShadow: "rgba(0, 0, 0, 0)",
                        }}
                        onClick={onOpen}>
                        <BekeuIcon src={helpCircle} />
                      </Button>
                    }
                  />
                ))}
              </ErrorBoundary>

              <ErrorBoundary fallback={<p>Something went wrong ClientItems</p>}>
                {mainState?.providers?.providers
                  ?.filter((providerItem) =>
                    providerItem.business_name
                      .toLowerCase()
                      .includes(providerInput.toLowerCase())
                  )
                  ?.map((provider) => (
                    <ProviderItem
                      key={provider.id}
                      item={provider}
                      handleEnable={handleEnable}
                      handleDisable={handleDisable}
                      handleBlock={handleBlock}
                      handleUnblock={handleUnblock}
                      listProviders={listProviders}
                      handleChangeInternalCode={handleChangeInternalCode}
                      handleSaveInternalCode={handleSaveInternalCode}
                      SAPNumber={SAPNumber}
                      result={mainState?.providers?.result}
                      //changeDeliveryTime={changeDeliveryTime}
                      //listShippingWithResult={listProvidersWithResult}
                    />
                  ))}
              </ErrorBoundary>
            </BoxBekeu>
          </ErrorBoundary>
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, { ...providers })(ClientWhiteListPage));
