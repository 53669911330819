import React, { useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import arrowRight from "../../design-system/icons/Arrows/arrow-right.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { INITIAL_STORAGED_FILTERS } from "../../constants/constants";

export const ProductDetailBreadcrumb = ({ product }) => {
  //hooks
  const history = useHistory();
  const mainState = useSelector((state) => state);

  //useState
  const [breadcrumbCategoryItems, setBreadcrumbCategoryItems] = useState([]);

  const storageFilters = INITIAL_STORAGED_FILTERS;

  //useEffect
  useEffect(() => {
    const breadcrumbItems = [];
    mainState?.categories?.categories?.forEach((category) => {
      if (category?.id === product?.category_id) {
        breadcrumbItems.push(
          {
            category_name: category?.name,
            category_id: category?.id,
          }
        );
        return;
      }
      category?.sub_categories?.forEach((cat) => {
        if (cat?.id === product?.category_id) {
          breadcrumbItems.push(
            {
              category_name: category?.name,
              category_id: category?.id,
            },
            {
              category_name: cat?.name,
              category_id: cat?.id,
            }
          );
          return;
        }
        cat?.sub_category_items?.forEach((c) => {
          if (c?.id === product?.category_id) {
            breadcrumbItems.push(
              {
                category_name: category?.name,
                category_id: category?.id,
              },
              {
                category_name: cat?.name,
                category_id: cat?.id,
              },
              {
                category_name: c?.name,
                category_id: c?.id,
              }
            );
          }
          return;
        });
      });
    });
    breadcrumbItems.push({
      category_name: product?.product_name,
      category_id: product?.category_id,
      current: true,
    });
    setBreadcrumbCategoryItems(breadcrumbItems);
    return () => {
        setBreadcrumbCategoryItems([]);
    }
  }, [mainState.categories.categories, product]);

  return (
    <Breadcrumb spacing="8px" separator={<BekeuIcon src={arrowRight} />}>
      {product && (
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => {
              localStorage.setItem(
                "storageFilters",
                JSON.stringify(storageFilters)
              );

              history.push(`/`);
            }}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Inicio
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {product &&
        breadcrumbCategoryItems?.map((item, id) => (
          <BreadcrumbItem key={id} isCurrentPage={item?.current && true}>
            <BreadcrumbLink
              onClick={() => {
                localStorage.setItem(
                  "storageFilters",
                  JSON.stringify(storageFilters)
                );

                history.push(
                  `/search/?page=1&per_page=20&category_id=${item.category_id}`
                );
              }}
              _hover={{ color: "brand.hover" }}
              fontFamily={"Open Sans"}
              fontWeight={400}
              fontSize={"14px"}
              title={`Ir a ${item?.category_name}`}
              color={item?.current && "brand.primary"}
            >
              {item?.category_name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};
