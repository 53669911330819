import axios from "axios";
import { urlsBase } from "../../../../config";

export const SHIPPING_URL = `${urlsBase.base_url}/api/v1/shipping_prices`;
export const PROVIDERS_URL = `${urlsBase.base_url}/api/v1/providers/increment`;
export const SHIPPING_PROVIDERS_URL = `${urlsBase.base_url}/api/v1/shipping-providers`;
export const MY_ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-sales`;

export function listShipping() {
  return axios.get(`${SHIPPING_URL}/provider`);
}

export function activatePause({ region_id }) {
  return axios.put(`${SHIPPING_URL}/${region_id}/state`);
}

export function massiveActivate() {
  return axios.put(`${SHIPPING_PROVIDERS_URL}/bulk-enable`);
}

export function massivePause() {
  return axios.put(`${SHIPPING_PROVIDERS_URL}/bulk-disable`);
}

export function massiveIncrease({ increment_shipping, free_shipping, reset_shipping }) {
  return axios.put(`${PROVIDERS_URL}`, {
    increment_shipping,
    free_shipping,
    reset_shipping,
  });
}

export function changeDeliveryTime({ shipping_provider_id, delivery_time }) {
  return axios.put(`${SHIPPING_PROVIDERS_URL}/${shipping_provider_id}`, {
    delivery_time,
  });
}
