import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  listCarts,
  confirmMyOrdersCart,
  claimMyOrdersCart,
  listDetailCart,
  getHistory,
  confirmMyOrdersCartUploadDispatchNote,
  getProviders,
} from "../cruds/myOrders.crud";

import { cartDetail } from "../../approveCartsPage/cruds/approveCarts.crud";

export const actionTypes = {
  LIST_CARTS: "LIST_CARTS",
  FILL_ORDER_LIST: "FILL_ORDER_LIST",
  FILL_ORDER_PAGINATION_DATA: "FILL_ORDER_PAGINATION_DATA",
  CONFIRM_MY_ORDERS_CART: "CONFIRM_MY_ORDERS_CART",
  CLAIM_MY_ORDERS_CART: "CLAIM_MY_ORDERS_CART",
  LIST_DETAIL_CART: "LIST_DETAIL_CART",
  FILL_ORDER_DETAIL: "FILL_ORDER_DETAIL",
  SET_RESULT: "SET_RESULT",
  GET_MY_ORDERS_HISTORY: "GET_MY_ORDERS_HISTORY",
  FILL_MY_ORDERS_HISTORY: "FILL_MY_ORDERS_HISTORY",
  GET_APPROVER_DETAILS: "GET_APPROVER_DETAILS",
  FILL_APPROVER_DETAILS: "FILL_APPROVER_DETAILS",
  FILL_PROVIDERS: "FILL_PROVIDERS",
};

const initialState = {
  carts: [],
  loading: false,
  cartsPagination: {
    currentPage: 1,
    perPage: 20,
    totalPages: 1,
    totalRows: 0,
  },
  orderDetail: {},
  result: null,
  myOrdersHistory: [],
  myOrdersHistoryLoading: false,
  myOrdersHistoryResult: null,
  approverDetails: [],
  providerList: [],
};

export const reducer = persistReducer(
  { storage, key: "demo1-orders", whitelist: ["carts"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_CARTS: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.LIST_DETAIL_CART: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_ORDER_LIST: {
        return {
          ...state,
          carts: action.payload,
          loading: false,
        };
      }
      case actionTypes.FILL_ORDER_DETAIL: {
        return {
          ...state,
          carts: action.payload,
          loading: false,
        };
      }

      case actionTypes.FILL_ORDER_PAGINATION_DATA: {
        return {
          ...state,
          cartsPagination: action.payload,
          loading: false,
        };
      }

      case actionTypes.CONFIRM_MY_ORDERS_CART: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.CLAIM_MY_ORDERS_CART: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.SET_RESULT: {
        return {
          ...state,
          result: action.payload,
          loading: false,
        };
      }

      case actionTypes.GET_MY_ORDERS_HISTORY: {
        return {
          ...state,
          myOrdersHistoryLoading: true,
        };
      }

      case actionTypes.FILL_MY_ORDERS_HISTORY: {
        return {
          ...state,
          myOrdersHistory: action.payload,
          myOrdersHistoryLoading: false,
        };
      }

      case actionTypes.GET_APPROVER_DETAILS: {
        return {
          ...state,
        };
      }

      case actionTypes.FILL_APPROVER_DETAILS: {
        return {
          ...state,
          approverDetails: action.payload,
          loading: false,
        };
      }

      case actionTypes.FILL_PROVIDERS: {
        return {
          ...state,
          providerList: action.payload,
        };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  listCarts: (value) => ({ type: actionTypes.LIST_CARTS, payload: value }),
  listDetailCart: (value) => ({
    type: actionTypes.LIST_DETAIL_CART,
    payload: value,
  }),

  fillOrderList: (value) => ({
    type: actionTypes.FILL_ORDER_LIST,
    payload: value,
  }),
  fillOrderDetail: (value) => ({
    type: actionTypes.FILL_ORDER_DETAIL,
    payload: value,
  }),
  fillOrderPaginationData: (value) => ({
    type: actionTypes.FILL_ORDER_PAGINATION_DATA,
    payload: value,
  }),
  confirmMyOrdersCart: (value) => ({
    type: actionTypes.CONFIRM_MY_ORDERS_CART,
    payload: value,
  }),
  claimMyOrdersCart: (value) => ({
    type: actionTypes.CLAIM_MY_ORDERS_CART,
    payload: value,
  }),
  result: (value) => ({
    type: actionTypes.SET_RESULT,
    payload: value,
  }),
  getHistory: (value) => ({
    type: actionTypes.GET_MY_ORDERS_HISTORY,
    payload: value,
  }),
  fillHistory: (value) => ({
    type: actionTypes.FILL_MY_ORDERS_HISTORY,
    payload: value,
  }),
  getApproverDetails: (value) => ({
    type: actionTypes.GET_APPROVER_DETAILS,
    payload: value,
  }),
  fillApproverDetails: (value) => ({
    type: actionTypes.FILL_APPROVER_DETAILS,
    payload: value,
  }),
  fillProviders: (value) => ({
    type: actionTypes.FILL_PROVIDERS,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(
    actionTypes.LIST_CARTS,
    function* listCartsSaga({ payload }) {
      try {
        const response = yield listCarts(payload);

        const pagination = response.data.message.pagination || {};
        const data = response.data.message.data.map((item) => {
          switch (item.state) {
            case 1:
              item.status = "pending";
              break;
            case 2:
              item.status = "approved";
              break;
            case 3:
              item.status = "rejected";
              break;
            default:
              break;
          }
          return item;
        });

        const providers = Object.values(response.data.message.providers).map(provider => {
          const obj = {
            name: provider.fantasy_name,
            provider: provider.id,
          }
          return obj;
        })

        providers.unshift({ name: "Todos", provider: "0" })
        
        if (response.status === 200) {
          yield put(actions.fillProviders(providers));
          yield put(actions.result(null));
          yield put(actions.fillOrderList(data));
          yield put(actions.fillOrderPaginationData(pagination));
        }
      } catch (err) {
        yield put(actions.result("generalError"));
      }
    },
  );

  yield takeLatest(
    actionTypes.CONFIRM_MY_ORDERS_CART,
    function* confirmMyOrdersCartSaga({ payload }) {
      const selector = yield select(getState);
      try {
      
        const dispatchNoteAvailable = !!selector?.auth?.features?.find(
          (str) => str === "dispatch_note",
        );
        
        let formdata = new FormData();

        if (dispatchNoteAvailable) {
          formdata.append("file", payload.file.file, payload.file.name);
        }
        formdata.append("dispatch_note_number", payload.dispatchNoteNumber);
        formdata.append("buy_order_id", payload.orderId);

        const { data } = yield confirmMyOrdersCart(payload, formdata);

        if (data.code === 200) {
          if (dispatchNoteAvailable) {
            const responseFormData =
              yield confirmMyOrdersCartUploadDispatchNote(formdata);

            if (responseFormData.data.message.code === 500) {
              yield put(
                actions.result({
                  type: "fileError",
                  error: [
                    "Hubo un error al subir el remito, por favor contactar con mesa de ayuda",
                  ],
                }),
              );
            } else {
              yield put(actions.result(true));
            }
          } else {
            yield put(actions.result(true));
          }
        }
      } catch (e) {
        yield put(
          actions.result({
            type: "generalError",
            error: ["Hubo un error al confirmar la recepción de mercadería"],
          }),
        );
      }
    },
  );

  yield takeLatest(
    actionTypes.CLAIM_MY_ORDERS_CART,
    function* claimMyOrdersCartSaga({ payload }) {
      try {
        const { data } = yield claimMyOrdersCart(payload);
        if (data.code === 200) {
          yield put(actions.result(true));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.result("generalError"));
      }
    },
  );

  yield takeLatest(
    actionTypes.LIST_DETAIL_CART,
    function* listDetailCartSaga({ payload }) {
      try {
        const response = yield listDetailCart(payload);

        const pagination = response.data.message.pagination || {};

        const data = response.data.message;
        if (response.status === 200) {
          yield put(actions.result(null));
          yield put(actions.fillOrderDetail(data.data));
          yield put(actions.fillOrderPaginationData(pagination));
        }
      } catch (err) {
        yield put(actions.result("generalError"));
      }
    },
  );

  yield takeLatest(
    actionTypes.GET_MY_ORDERS_HISTORY,
    function* listGetOrdersSaga({ payload }) {
      try {
        const response = yield getHistory(payload);

        const data = response.data.message;

        if (response.status === 200) {
          yield put(actions.fillHistory(data));
        }
      } catch (err) {
        yield put(actions.result("generalError"));
      }
    },
  );

  yield takeLatest(
    actionTypes.GET_APPROVER_DETAILS,
    function* getApproverDetailsSaga({ payload }) {
      try {
        const response = yield cartDetail(payload);

        const data = response.data.message;

        if (response.status === 200) {
          yield put(actions.fillApproverDetails(data));
        }
      } catch (err) {
        yield put(actions.result("generalError"));
      }
    },
  );
}
