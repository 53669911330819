import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Flex,
  Img,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  OrderedList,
  useDisclosure,
  Text
} from "@chakra-ui/react";

import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";

import FormUploadDispatchNote from "./FormUploadDispatchNote";

import errorCircle from "../../design-system/icons/Errors/error-circle.svg";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";

import { MAX_FILE_SIZE } from "../../constants/constants";

import useFeatures from '../../hooks/useFeatures';

const Confirmation = ({
  onClose,
  handleConfirm,
  loading,
  setFileNameInput,
  fileNameInput,
  sapAvailable,
  dispatchNoteAvailable,
  msgFileNameInput,
}) => {

  const { sapUser, proUser} = useFeatures();

  useEffect(() => {
    setFileNameInput({ name: "", file: null });
  }, []);

  let disabledConfirmButton = false;
  if (dispatchNoteAvailable) {
    disabledConfirmButton = fileNameInput?.name?.length <= 0;
  }
  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      p={"3rem 3rem 1rem 3rem"}
      gap={4}
    >
      <H5 textAlign="center">
        ¿Estás seguro que querés confirmar la recepción de la compra?
      </H5>

    
          {dispatchNoteAvailable ? (
            <FormUploadDispatchNote
              setFileNameInput={setFileNameInput}
              fileNameInput={fileNameInput}
              sapAvailable={sapAvailable}
              dispatchNoteAvailable={dispatchNoteAvailable}
              maxFileSize={MAX_FILE_SIZE.MB2ALL}
              allowedFormats={MAX_FILE_SIZE?.MB2ALL?.format}
              msgFileNameInput={msgFileNameInput}
            />
          ) : null}
     

      <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
      <Flex alignItems={"center"} width="100%" justifyContent={"space-between"}>
        <ButtonBekeuOutline mr={2} onClick={onClose}>
          No, cancelar
        </ButtonBekeuOutline>
        <ButtonBekeu
          ml={2}
          onClick={handleConfirm}
          isLoading={loading}
          isDisabled={disabledConfirmButton}
        >
          Si, confirmar
        </ButtonBekeu>
      </Flex>
    </Flex>
  );
};

const UpdateChanges = ({
  onClose,
  setUpdate,
  cartsState,
  dispatchNote,
  handleSetDispatchNote,
}) => {

  const history = useHistory();
  
  const orderHasErrors = cartsState?.result?.error?.length === 0;
  
  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"} 
      p={"3rem 0 2.5rem 0"}
      gap={6}
    >
      <Img src={!orderHasErrors ? success : errorCircle} mb={3}/>
      {!orderHasErrors ? (
        <Flex
          flexDir={"column"}
          justifyContent="center"
          alignItems="center"
          gap={4}
          mb={2}
        >
          <H5 textAlign="center" mb={2}>
            ¡La recepción de tu compra fue realizada con éxito!
          </H5>

          <Text textAlign={"center"} color={"brand.contentSecondary"}>
            Confirmaste la entrega
            <br />
            del N° de remito {dispatchNote}
          </Text>
        </Flex>
      ) : (
        <>
          <H5 textAlign="center" py={2}>
            Ocurrió un error al confirmar tu compra
          </H5>
          <OrderedList>
            {cartsState?.result &&
              cartsState?.result?.error?.map((item, id) => (
                <ListItem>{item}</ListItem>
              ))}
          </OrderedList>
        </>
      )}
      <ButtonBekeu
        w={["75%", "13.125rem", "13.125rem", "13.125rem"]}
        m={0}
        onClose={onClose}
        onClick={() => {
          handleSetDispatchNote("");
          setUpdate(false);
          history.go(0);
        }}
      >
        Aceptar
      </ButtonBekeu>
    </Flex>
  );
};

export const MyOrdersConfirmRejectModal = ({
  isDisabled,
  children,
  handleConfirmOrderCart,
  handlePagination,
  orderItem,
  dispatchNoteNumber,
  setFileNameInput,
  fileNameInput,
  sapAvailable,
  dispatchNoteAvailable,
  shippingIncluded,
  msgFileNameInput,
  ...props
}) => {
  const cartsState = useSelector((state) => state?.carts);
  const ordersState = useSelector((state) => state?.orders);
  const authState = useSelector((state) => state?.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dispatchNote, setDispatchNote] = useState(""); 

  const history = useHistory();

  
  useEffect(() => {
    if (ordersState?.result) {
      setUpdate(false);
      setLoading(false);
    }
  }, [ordersState?.result]);

  const handleConfirm = () => {
    setLoading(true);
    handleSetDispatchNote(dispatchNoteNumber);
    handleConfirmOrderCart(orderItem?.id, dispatchNoteNumber);
  };


  //Dispatch note a enviar 
  const handleSetDispatchNote = (number) => {
    setDispatchNote(number)
  }
  return (
    <>
      <ButtonBekeu
        ml="0.75rem"
        width={"100%"}
        onClick={onOpen}
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </ButtonBekeu>
      
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={!update ? "lg" : "xl"}
        onCloseComplete={() => {
          if(!update) return handlePagination();
          if (ordersState?.result) {
            setUpdate(false);
          }
        }}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"0.75rem"}>
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {update ? (
              <Confirmation
                onClose={onClose}
                handleConfirm={handleConfirm}
                setFileNameInput={setFileNameInput}
                fileNameInput={fileNameInput}
                history={history}
                loading={loading}
                dispatchNoteAvailable={dispatchNoteAvailable}
                sapAvailable={sapAvailable}
                shippingIncluded={shippingIncluded}
                msgFileNameInput={msgFileNameInput}
                authState={authState}
              />
            ) : (
              <UpdateChanges
                onClose={onClose}
                history={history}
                handlePagination={handlePagination}
                setFileNameInput={setFileNameInput}
                fileNameInput={fileNameInput}
                setUpdate={setUpdate}
                cartsState={cartsState}
                shippingIncluded={shippingIncluded}
                authState={authState}
                dispatchNote={dispatchNote}
                handleSetDispatchNote={handleSetDispatchNote}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
