import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import arrowRight from "../../design-system/icons/Arrows/arrow-right.svg";

import { useHistory, useLocation } from "react-router-dom";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

export const WhiteListBreadcrumb = () => {
  const location = useLocation().pathname;
  const history = useHistory();

  return (
    <Breadcrumb spacing="8px" separator={<BekeuIcon src={arrowRight} />}>
      <BreadcrumbItem>
        <BreadcrumbLink
          onClick={() => history.push("/")}
          _hover={{ color: "brand.hover" }}
          fontFamily={"Open Sans"}
          fontWeight={400}
          fontSize={"14px"}
        >
          Inicio
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink
          color={location === "/my-providers" && "brand.primary"}
          onClick={() => history.push("/my-providers")}
          _hover={{ color: "brand.hover" }}
          fontFamily={"Open Sans"}
          fontWeight={400}
          fontSize={"14px"}
        >
          Mis proveedores
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
