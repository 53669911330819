import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { injectIntl } from "react-intl";
import useQuery from "../../hooks/useQuery";

import { Flex, Progress } from "@chakra-ui/react";
import { H5 } from "../../design-system/headings/H5";
import ApproveCartOrdersItem from "../../components/ApproveCartsItem/ApproveCartOrdersItem";

import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import { actions as cartActions } from "../approveCartsPage/ducks/approveCarts.duck";

import useFeatures from "../../hooks/useFeatures";
import { ApproveCartsBreadcrumb } from "../../components/ApproveCartsBreadcrumb/ApproveCartsBreadcrumb";

const CartDetailPage = (props) => {
  const params = useParams();
  const cartsApproval = useSelector((state) => state.carts_approval);

  const query = useQuery();
  const state = query.get("state");

  const { shippingIncludedUser } = useFeatures();

  useEffect(() => {
    props.cartDetail({ cart_id: params.cartId });
  }, []);

  const filteredItem = cartsApproval.cart;

  const handleApproveCart = (item) => {
    props.approveCart(item);
  };

  const handleRejectCart = (item) => {
    props.rejectCart(item);
  };

  const handleApproveCartOrder = (item) => {
    props.approveCartOrder(item);
  };

  const handleRejectCartOrder = (item) => {
    props.rejectCartOrder(item);
  };

  const handlePagination = () => {
    props.cartDetail({ cart_id: params.cartId });
  };

  function handleDeleteSuccess() {
    props.cartDetail({ cart_id: params.cartId });
  }

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"2.5rem"}
        bg={"brand.background"}
        maxW={"75rem"}
        w="100%"
      >
        <ApproveCartsBreadcrumb cart_id={params.cartId}/>
        <H5 mt={"2rem"}>
          Detalle de compra
        </H5>
        {cartsApproval.approvalLoading ? (
          <Progress
            size="xs"
            isIndeterminate
            colorScheme={"purple"}
            mt={"10"}
          />
        ) : filteredItem && filteredItem.length > 0 ? (
          filteredItem.map((item, id) => {
            return (
              <ApproveCartOrdersItem
                mt={"2rem"}
                type={item.state}
                key={id}
                handleDeleteSuccess={handleDeleteSuccess}
                item={item}
                showitems
                selected
                status={item.status === "pending" ? true : undefined}
                handleApproveCart={handleApproveCart}
                handleRejectCart={handleRejectCart}
                handlePagination={handlePagination}
                params={params}
                state={state}
                shippingIncluded={shippingIncludedUser}
              />
            );
          })
        ) : (
          "No se encontraron resultados"
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...cartActions, ...product.actions })(CartDetailPage),
);
